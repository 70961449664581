import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import React, {useRef, useState} from "react";
import {Api} from "../../service/Api";
import {CheckCircleOutlined} from "@ant-design/icons";
import Footer from "../../components/footer";
import {Helper} from "../../utils/Helper";
import {Helmet} from "react-helmet";

const forWrapStyle: React.CSSProperties = {
    maxWidth: 500,
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
}

export default function Entrust() {
    const [type, setType] = useState<number>(1)
    const [phone, setPhone] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [remark, setRemark] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [phoneValid, setPhoneValid] = useState<boolean>();
    const [phoneValidMsg, setPhoneValidMsg] = useState<string>();

    const [nameValid, setNameValid] = useState<boolean>();
    const [nameValidMsg, setNameValidMsg] = useState<string>()

    const [remarkValid, setRemarkValid] = useState<boolean>();
    const [remarkValidMsg, setRemarkValidMsg] = useState<string>();

    const [emailValid, setEmailValid] = useState<boolean>();
    const [emailValidMsg, setEmailValidMsg] = useState<string>();

    const instanceRef = useRef<{ hasFirstCheck: boolean }>({hasFirstCheck: false})

    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string>('')

    function onSubmit(e: React.FormEvent) {
        e.preventDefault();
        const params = {
            type: type,
            phone: phone,
            name: name,
            detail: remark,
            email: email,
        }
        const validateRes = [
            validateName(),
            validatePhone(),
            validateRemark(),
            validateEmail(),
        ]
        
        if (validateRes.filter(i => !i).length > 0) {
            return
        }

        instanceRef.current.hasFirstCheck = true

        // console.log(params)
        Api.createEntrust(params).then((res) => {
            // console.log(res);
            if (res.RowsAffected === 1) {
                setShowSuccess(true)
            } else {
                setErrMsg(res.Error || '未知错误')
            }
        })
    }


    function validateName() {
        if (!name) {
            setNameValid(true)
            setNameValidMsg("请输入联系人信息")
            return false
        }

        if (name.length > 20) {
            setNameValid(true)
            setNameValidMsg("联系人不能超过20个字")
            return false
        }

        setNameValid(false)
        setNameValidMsg('')
        return true
    }

    function validatePhone() {
        if (!phone) {
            setPhoneValid(true)
            setPhoneValidMsg("请输入联系电话")
            return false;
        }

        if (!/^[\d-]{8,}$/.test(phone)) {
            setPhoneValid(true)
            setPhoneValidMsg("请输入正确格式的联系电话")
            return false
        }

        setPhoneValid(false)
        setPhoneValidMsg('')
        return true
    }

    function validateRemark() {
        if (!remark) {
            setRemarkValid(true)
            setRemarkValidMsg("请填写需求描述信息")
            return false
        }

        if (remark.length > 1024) {
            setRemarkValid(true)
            setRemarkValidMsg("字数不能超过1000个字")
            return false
        }

        setRemarkValid(false)
        setRemarkValidMsg('')
        return true
    }

    function validateEmail() {
        let m = email.trim();
        if (m !== "" && !/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(m)) {
            setEmailValid(true)
            setEmailValidMsg("输入正确格式的邮箱")
            return false
        }

        setEmailValidMsg('')
        setEmailValid(false)
        return true;
    }

    function doClickBack() {
        setShowSuccess(false);
        setName('')
        setRemark('')
        setPhone('')
        setType(1)
        setEmail('')
    }

    return (
        <div>
            <Helmet>
                <title>{Helper.htmlTitle('出租/求租')}</title>
            </Helmet>
            <Header/>
            <Container>
                <div style={forWrapStyle} className={'page-main-content'}>
                    {
                        !showSuccess ? (
                            <form onSubmit={onSubmit}>
                                <div className="form-group">
                                    <label htmlFor="el-username"><span className="text-danger">*</span>联系人</label>
                                    <input type="text"
                                           className={`form-control ${nameValid ? 'is-invalid' : ''}`}
                                           maxLength={20} id="el-username" value={name} onChange={e => {
                                        setName(e.target.value);
                                    }}/>
                                    {/*<small className="form-text text-muted"></small>*/}
                                    <div className="invalid-feedback">{nameValidMsg}</div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="el-phone"><span className="text-danger">*</span>联系电话</label>
                                    <input type="text"
                                           className={`form-control ${phoneValid ? 'is-invalid' : ''}`}
                                           id="el-phone" value={phone} max={20} onChange={(e) => setPhone(e.target.value)} />
                                    <div className="invalid-feedback">{phoneValidMsg}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="el-email">电子邮箱</label>
                                    <input type="text"
                                           className={`form-control ${emailValid ? 'is-invalid' : ''}`}
                                           id="el-email" value={email} max={20} onChange={(e) => setEmail(e.target.value)} />
                                    <div className="invalid-feedback">{emailValidMsg}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="el-type"><span className="text-danger">*</span>您的需求类型</label>
                                    <div>
                                        <div className={"btn-group btn-block"}>
                                            <button className={`btn ${type === 1 ? 'btn-primary' : 'btn-outline-primary'}`} type="button" onClick={() => setType(1)}>我要找房</button>
                                            <button className={`btn ${type === 2 ? 'btn-primary' : 'btn-outline-primary'}`} type="button" onClick={() => setType(2)}>有房出租</button>
                                            <button className={`btn ${type === 99 ? 'btn-primary' : 'btn-outline-primary'}`} type="button" onClick={() => setType(99)}>商务合作</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="el-type"><span className="text-danger">*</span>需求描述</label>
                                    <textarea
                                        className={`form-control ${remarkValid ? 'is-invalid' : ''}`}
                                        rows={3} maxLength={1024} value={remark} onChange={(e) => { setRemark(e.target.value) }}/>
                                    <div className="invalid-feedback">{remarkValidMsg}</div>
                                </div>
                                <div className="form-group">
                                    <button className={"btn btn-primary btn-block"} type={'submit'}>提交</button>
                                </div>
                                <div className="form-group">
                                    <div className={"text-center mt-4 font-size-sm text-danger"}>{errMsg}</div>
                                </div>
                            </form>
                        ) : (
                            <div className={"text-center"}>
                                <div style={{fontSize: 48}} className={"text-success mb-4"}>
                                    <CheckCircleOutlined />
                                </div>
                                <div>
                                    您的信息已提交 <br/>
                                    我们将及时跟进处理
                                </div>

                                <div className="mt-4">
                                    <button className="btn btn-primary" style={{width: 120}} onClick={doClickBack}>返回</button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Container>
            <Footer />
        </div>
    )
}