import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import React, {useContext, useEffect, useState} from "react";
import {Api} from "../../service/Api";
import {INews} from "../../service/types/news";
import {Helper} from "../../utils/Helper";
import MyPagination from "../../components/MyPagination/MyPagination";
import style from './style.module.scss'
import {useHistory} from 'react-router-dom'
import Footer from "../../components/footer";
import {Helmet} from "react-helmet";
import {GlobalContext} from "../../global/GlobalContext";

export default function NewsList() {
    const history = useHistory()
    const [list, setList] = useState<INews[]>()
    const [pageNo, setPageNo] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(20)
    const [total, setTotal] = useState<number>(0)
    const {isM} = useContext(GlobalContext)

    useEffect(() => {
        Api.newsList(pageNo, pageSize).then((data) => {
            // console.log(data)
            setList(data.list);
            setTotal(data.total)
        })
    }, [pageNo, pageSize])

    function changePage(page_no: number, page_size: number) {
        setPageNo(page_no)
        setPageSize(page_size)
    }

    function doNewsClick(item: INews) {
        return (e: React.MouseEvent) => {
            e.preventDefault();
            if (isM) {
                history.push("/news/" + item.id)
                return
            }
            window.open(window.location.origin + "/news/" + item.id)
        }
    }

    return <div>
        <Helmet>
            <title>{Helper.htmlTitle('新闻')}</title>
        </Helmet>
        <Header />
        <Container>
            <div className={`page-main-content ${style.NewsWrap} mt-4`}>
                {
                    list?.map((item) => {
                        return <a href={"/news/" + item.id} key={item.id} onClick={doNewsClick(item)} className={`text-decoration-none d-block ${style.NewsItem}`}>
                            <h2 className={`text-truncate ${style.NewsItemTitle}`} title={item.title}>{item.title}</h2>
                            <small className="text-muted">{Helper.formatDate(item.publish_time)}</small>
                        </a>
                    })
                }
            </div>

            <MyPagination total={total} current={pageNo} onChange={changePage} />
        </Container>
        <Footer />
    </div>
  }
  