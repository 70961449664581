import {ICheckItem} from "./index";
import React, {useState} from "react";
import style from "./style.module.scss";

interface IListSelector {
    onChange: (val: ICheckItem | undefined) => void
    visible: boolean
    onClose: () => void
    list: ICheckItem[]
}
export function ListSelector({onChange, visible, onClose, list}: IListSelector) {
    // const [priceList, setPriceList] = useState<ICheckItem[]>([]);
    const [roomArea, setRoomArea] = useState<ICheckItem>();

    function onItemChange(item: ICheckItem) {
        setRoomArea(item)
    }

    function doSubmit() {
        onChange?.(roomArea)
    }

    function doReset() {
        setRoomArea(undefined)
    }

    if (!visible) {
        return null
    }

    return (
        <>
            <div className={style.dropDownPanel}>
                <div className={"d-flex flex-column"} style={{height: '100%'}}>
                    <div style={{height: 0}} className={'flex-fill border-bottom text-center'}>
                        {
                            list.map((item) => {
                                return <div
                                    key={item.id}
                                    className={`${ style.selectItem } ${item === roomArea ? style.active : ''}`}
                                    onClick={() => onItemChange(item)}
                                >{item.label}</div>
                            })
                        }
                    </div>
                    <div className={"container-fluid"}>
                        <div className={"row py-3"}>
                            <div className={"col-6"}>
                                <button className={"btn btn-block btn-secondary"} onClick={doReset}>重置</button>
                            </div>
                            <div className={"col-6"}>
                                <button className={"btn btn-block btn-primary"} onClick={doSubmit}>确定</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.dropMask} onClick={onClose}/>
        </>
    )
}