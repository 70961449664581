import React, {useEffect, useRef, useState} from "react";
import style from './style.module.scss'

let baiduMapApiLoadingPromise: Promise<any>
function loadBaiduMapApiScript(): Promise<any> {
    if (baiduMapApiLoadingPromise) {
        return baiduMapApiLoadingPromise
    }
    return baiduMapApiLoadingPromise = new Promise<any>((resolve) => {
        (window as any).__baidu_map_initialize = function () {
            resolve(true)
        }
        const script = document.createElement("script");
        script.src = "https://api.map.baidu.com/api?v=1.0&type=webgl&ak=GwQ4RlZDQNdHY2asnsxGKuyRPy3mtGP9&callback=__baidu_map_initialize";
        document.body.appendChild(script);
    })
}

interface ISearchResult {
    address: string
    city: string
    point: {lat: number, lng: number}
    title: string
    uid: string
    type: number
    dis?: number
}

// 基础数据
const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const icons = [
    {
        active: "//pic7.58cdn.com.cn/nowater/fangfe/n_v235b12beabdab41468ea78d148d6f2518.png",
        normal: "//pic7.58cdn.com.cn/nowater/fangfe/n_v29420c016469241fb88439dc87af017bb.png"
    }, {
        active: "//pic7.58cdn.com.cn/nowater/fangfe/n_v235b12beabdab41468ea78d148d6f2518.png",
        normal: "//pic7.58cdn.com.cn/nowater/fangfe/n_v29420c016469241fb88439dc87af017bb.png"
    }, {
        normal: "//pic7.58cdn.com.cn/nowater/fangfe/n_v219ed19f9bacd4becac77f9c1935fa1e2.png",
        active: "//pic8.58cdn.com.cn/nowater/fangfe/n_v21202b49eaffa4dffa3f7fc4cd7f46aae.png"
    }, {
        normal: "//pic5.58cdn.com.cn/nowater/fangfe/n_v2f4ad13f849a34e82a4ffcb9c8de18113.png",
        active: "//pic8.58cdn.com.cn/nowater/fangfe/n_v2d6fada5dfe2e4b458dae6e21a69b5f7d.png"
    }, {
        normal: "//pic6.58cdn.com.cn/nowater/fangfe/n_v2b259be71862042c69a52c6b955f638a5.png",
        active: "//pic2.58cdn.com.cn/nowater/fangfe/n_v218dd117467b24208bdb7296b3991e8ae.png"
    }, {
        active: "//pic8.58cdn.com.cn/nowater/fangfe/n_v24e446c9801824b88a521a9075ea066ef.png",
        normal: "//pic8.58cdn.com.cn/nowater/fangfe/n_v218c649bfa35b41e2828db151782815cc.png"
    }, {
        active: "//pic8.58cdn.com.cn/nowater/fangfe/n_v2e3c3a690c3534cf6b3245eebcb37dcc7.png",
        normal: "//pic5.58cdn.com.cn/nowater/fangfe/n_v2c150e0e3447c40bab23cbb93d51454eb.png"
    }
]

const navList: string[] = ['公交', '地铁', '购物', '医院', '学校', '银行', '餐饮']

interface IProps {
    lat: number | string
    lng: string | number
}

export default function BaiduMap({lat, lng}: IProps) {
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const [currNav, setCurrNav] = useState<string>(navList[0])
    const [searchResult, setSearchResult] = useState<ISearchResult[]>([])
    const [activeResult, setActiveResult] = useState<ISearchResult>()

    const baiduMapSearchInstance = useRef<(keyword: string) => void>();
    const baiduMapToggleInstance = useRef<(item: ISearchResult) => void>();
    const baiduMapInstance = useRef<any>(undefined)

    useEffect(() => {
        let isCancel = false;
        async function init() {
            await loadBaiduMapApiScript();
            if (isCancel) {
                return
            }
            if (mapContainerRef.current === null) {
                throw new Error("地图容器对象存在")
            }

            const map = new window.BMapGL.Map(mapContainerRef.current);
            const point = new window.BMapGL.Point(lng, lat);// 116.404, 39.915
            map.centerAndZoom(point, 14);
            map.enableScrollWheelZoom(true);
            // map.setHeading(64.5);
            // map.setTilt(73);
            var marker = new window.BMapGL.Marker(point);
            map.addOverlay(marker);

            var scaleCtrl = new window.BMapGL.ScaleControl();  // 添加比例尺控件
            map.addControl(scaleCtrl);

            var opts = {
                anchor: (window as any).BMAP_ANCHOR_BOTTOM_LEFT,
                offset: new window.BMapGL.Size(20, 50)
            }

            var zoomCtrl = new window.BMapGL.ZoomControl(opts);  // 添加缩放控件
            map.addControl(zoomCtrl);

            let oldNormalMarker: any = undefined
            let oldActiveMarker: any = undefined

            let itemMapperMarker = new Map();

            baiduMapInstance.current = map

            // var cityCtrl = new window.BMapGL.CityListControl();  // 添加城市列表控件
            // map.addControl(cityCtrl);

            function getDistance({lng, lat}: any) {
                return map.getDistance(point, new window.BMapGL.Point(lng, lat))
            }

            function handlePoints(points: ISearchResult[]) {
                oldNormalMarker = undefined
                oldActiveMarker = undefined

                // 先清空地图上的所有点
                map.clearOverlays();
                points.forEach((item) => {
                    item.dis = (getDistance(item.point) / 1000)
                })
                points.sort((a, b) => {
                    return a.dis! - b.dis!
                })

                itemMapperMarker.clear()

                points.forEach((item, t) => {
                    setMarker(item)
                })

                var marker = new window.BMapGL.Marker(point);
                map.addOverlay(marker);

                setSearchResult(points);
            }

            let searchText = ''

            function getIconIndex() {
                let index = navList.indexOf(searchText)
                if (index < 0) {
                    return 0
                }
                return index
            }

            function toggleMarker(item: ISearchResult, _marker: any) {
                // 设置高亮的节点
                setActiveResult(item);

                // 移除旧的currActiveMarker，添加上旧的 oldNormalMarker
                if (oldActiveMarker) {
                    map.removeOverlay(oldActiveMarker)
                }

                if (oldNormalMarker) {
                    map.addOverlay(oldNormalMarker)
                }

                oldNormalMarker = _marker;

                console.log('clicked me')
                let newIcon = new window.BMapGL.Icon(icons[getIconIndex()].active, new window.BMapGL.Size(32, 36), {
                    imageSize: new window.BMapGL.Size(32,36)
                })

                map.removeOverlay(_marker);

                var _activeMarker = new window.BMapGL.Marker(_marker.getPosition(),{
                    icon: newIcon
                })
                map.addOverlay(_activeMarker)

                oldActiveMarker = _activeMarker;
            }

            function setMarker(item: ISearchResult) {
                var _point = new window.BMapGL.Point(item.point.lng, item.point.lat)
                var _icon = new window.BMapGL.Icon(icons[getIconIndex()].normal, new window.BMapGL.Size(32,36), {
                    imageSize: new window.BMapGL.Size(32,36)
                })

                var _marker = new window.BMapGL.Marker(_point, {icon: _icon })

                itemMapperMarker.set(item, _marker)

                _marker.addEventListener("click", function() {
                    toggleMarker(item, _marker)
                })
                map.addOverlay(_marker)
            }

            var localSearch = new window.BMapGL.LocalSearch(map, {
                pageCapacity: 20,
                onSearchComplete: function(res: any) {
                    console.log('搜索结果',res)
                    var result = [];
                    if (0 === localSearch.getStatus()) {
                        for(var n = 0; n < res.getCurrentNumPois(); n++) {
                            result.push(res.getPoi(n))
                        }
                        console.log('结果是', result)
                    } else if (2 === localSearch.getStatus()) {
                        console.log('结果为空哦')
                    }

                    handlePoints(result)
                }
            })
            // localSearch.search("地铁", { forceLocal: true })
            // localSearch.search("地铁", { forceLocal: true })
            // localSearch.searchNearby("公交", point, 3e3)

            function doSearch(keyword: string) {
                searchText = keyword
                localSearch.searchNearby(keyword, point, 3e3)
            }

            function reToggleMarker(item: ISearchResult) {
                toggleMarker(item, itemMapperMarker.get(item))
            }

            baiduMapSearchInstance.current = doSearch
            baiduMapToggleInstance.current = reToggleMarker

            doSearch(navList[0])
        }
        init().catch((err) => {
            console.error('百度API初始化失败了')
        })

        return () => {
            isCancel = true
            baiduMapInstance.current?.destroy?.()
        }
    }, [])

    function doClickNav(item: string) {
        setCurrNav(item)
        if (baiduMapSearchInstance.current) {
            baiduMapSearchInstance.current?.(item)
        }
    }

    const [showNearby, setShowNearby] = useState<boolean>(false)

    return (
        <div>
            <div className={style.mapContainer}>
                <div ref={mapContainerRef} className={style.mapContainerStyle}/>
                <div className={style.closeMapOverlay}>
                    <span className={'text-primary'} onClick={() => setShowNearby(v => !v)}>{showNearby ? '隐藏周边' : '显示周边'}</span>
                </div>
                <div className={`${style.mapOverlay} ${showNearby ? '': style.mapOverlayHide }`}>
                    <div className={style.overlayNav}>
                        {
                            navList.map((item) => {
                                return <div onClick={() => doClickNav(item)} className={`${style.overlayNavItem} ${currNav === item ? style.overlayNavItemActive: ''}`} key={item}>{item}</div>
                            })
                        }
                    </div>
                    <div className={style.overlayNavContent}>
                        {
                            searchResult.map((item, index) => {
                                return (
                                    <div
                                        key={item.uid}
                                        className={`${style.resultItem} ${item.uid === activeResult?.uid ? style.resultItemActive : ''}`}
                                        onClick={() => baiduMapToggleInstance.current?.(item)}
                                    >
                                        <div className={style.resultItemTitle}>
                                            <div className={style.resultItemLeft}>{letters[index]} {item.title}</div>
                                            <small className={style.resultItemDis}>{Number(item.dis?.toFixed(2))}km</small>
                                        </div>
                                        <div className={"text-truncate text-muted"}><small>{item.address}</small></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}