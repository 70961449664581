import Carousel from 'react-bootstrap/Carousel'
import style from './ViewImage.module.scss'
import {CloseOutlined} from "@ant-design/icons";
import {ImageItem} from "./type/type";
import {useEffect, useState} from "react";

interface IProps {
    visible: boolean
    list: ImageItem[]
    index?: number
    onClose?: () => void
}

export default function ViewImageV2({visible, list, index, onClose}: IProps) {
    // 当前选中的Index
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        setActiveIndex(index || 0);
    }, [index, visible])

    useEffect(() => {
        function onEscapeHandler(e: KeyboardEvent) {
            if (e.key === "Escape") {
                onClose?.()
                return
            }

            if (e.key === "ArrowLeft") {
                setActiveIndex((i) => {
                    let result = i - 1
                    if (result < 0) {
                        return list.length - 1
                    }
                    return result
                })
                return
            }

            if (e.key === "ArrowRight") {
                setActiveIndex((i) => {
                    let result = i + 1
                    if (result >= list.length) {
                        return 0
                    }
                    return result
                })
                return
            }
        }

        if (visible) {
            document.body.style.overflow = 'hidden'
            document.addEventListener('keydown', onEscapeHandler)
        } else {
            document.body.style.overflow = 'auto'
        }

        return () => {
            document.body.style.overflow = 'auto'
            document.removeEventListener('keydown', onEscapeHandler)
        }
    }, [visible, onClose, list])

    function handleSelect(selectedIndex: number) {
        setActiveIndex(selectedIndex);
    }

    if (!visible) {
        return null
    }

    return <div className={style.ViewImag}>
        <div className={style.close} onClick={() => onClose?.()}><CloseOutlined /></div>
        <div className={style.numberIndicator}><samp>{activeIndex + 1} / {list.length}</samp></div>
        <Carousel interval={null} activeIndex={activeIndex} onSelect={handleSelect} className={style.myCarousel} prevLabel={"上一张"} nextLabel={"下一张"}>
            {
                list.map((item) => {
                    return <Carousel.Item key={item.id}>
                        <div className={style.imgWrap}>
                            <img className={style.img1} src={item.src} alt="图片"/>
                        </div>
                    </Carousel.Item>
                })
            }
        </Carousel>
    </div>
}