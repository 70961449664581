import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {Api} from "../../service/Api";
import {INews} from "../../service/types/news";
import "./detail.scss"
import {Helper} from "../../utils/Helper";
import {FrownOutlined, LoadingOutlined} from "@ant-design/icons";
import Footer from "../../components/footer";

export default function NewsDetail() {
    const {id} = useParams<{id: string}>()
    const [news, setNews] = useState<INews>()
    const [loading ,setLoading] = useState<boolean>(true)

    useEffect(() => {
        setLoading(true)
        Api.newsDetail(id).then((data) => {
            setNews(data)
            window.document.title = data.title
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [id])

    return (
        <div>
            <Header />
            <Container className={'page-main-content'}>
                {
                    news && news.id > 0 ? <div className={"mt-4"}>
                        <h2 className="mb-3">{news?.title}</h2>
                        <div className="mb-3">
                            <small className={"text-muted"}>{Helper.formatDate(news.publish_time)}</small>
                        </div>
                        <main dangerouslySetInnerHTML={{__html: news.content}} className={"news-detail-main"}/>
                    </div> : null
                }

                {
                    loading ? <>
                        <div className={"text-center mt-4"}>
                            <LoadingOutlined style={{fontSize: 32, color: "#007bff"}}/>
                            <div className={"text-muted mt-2"}>加载中...</div>
                        </div>
                    </> :null
                }

                {
                    !loading && (!news || news.id === 0) ? (
                        <div className={"text-center"} style={{marginTop: 100}}>
                            <FrownOutlined style={{fontSize: 48, color: '#dce0e6'}}/>
                            <div className="text-muted mt-3">新闻信息不存在</div>
                        </div>
                    ) : null
                }
            </Container>
            <Footer />
        </div>
    )
}