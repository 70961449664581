import style from './style.module.scss'
import qrCodeImg from './qrcode.jpeg';

const serviceList = [
    '商办租赁', '金融服务', '工商服务', '会展策划', '会议选址',
    '产品策划', '商务出行', '团队拓展', '资源对接', '财税规划',
    '法律咨询', '办公设备', '装修设计', '网站建设', '广告宣传'
]

const serviceListGroup = [serviceList.slice(0, 5), serviceList.slice(5, 10), serviceList.slice(10, 15)]

export default function Footer() {
    const year = new Date().getFullYear()
    return <footer className={style.footer}>
        <div className={"container"}>
            <div className={style.footerTop}>
                <div className={"row text-left"}>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <div className={style.sTitle}>智远企服（北京）信息技术有限公司</div>
                        <div>
                            是一家专业从事企业服务的公司
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <div>
                            <div className={style.sTitle}>使命</div>
                            <div>做企业后方有保障、值得信赖的行政助理</div>
                        </div>
                    </div>
                </div>

                <div className={"row text-left"}>
                    <div className={"col-sm-12 col-md-6 mb-3"}>
                        <div>
                            <div className={style.sTitle}>服务板块</div>
                            <div>
                                {serviceListGroup.map((item,index) => {
                                    return <div key={index}>
                                        {item.map((ii) => {
                                            return <span className={style.serviceItem} key={ii}>{ii}</span>
                                        })}
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className={"row"}>
                            <div className={"col-auto mb-3"}>
                                <div className={style.sTitle}>联系我们</div>
                                <div>
                                    <div>电话：010-64706288</div>
                                    <div>邮箱：13051215688@wo.cn</div>
                                    <div>地址：北京市朝阳区望京方恒国际中心</div>
                                </div>
                            </div>
                            <div className={"col-auto mb-3"}>
                                <img src={qrCodeImg} className={style.qrCodeImg} alt="二维码"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.footerBottom}>
            <div>版权所有 ©{year} 智远企服（北京）信息技术有限公司</div>
            <a href="http://beian.miit.gov.cn" target="_blank">京ICP备2021025410号-1</a>
        </div>

    </footer>
}