import {useHistory, NavLink} from 'react-router-dom'
import HeaderTail from './HeaderTail';
import Container from "../Container/Container";
import style from './Header.module.scss';
import logoUrl from './logo.png';
import {MenuOutlined} from "@ant-design/icons";
import {useState} from "react";

function Header() {
  const history = useHistory();
  const [showToggleMenu, setShowToggleMenu] = useState<boolean>(false)
  return <div className={style.headerWrap}>
    <Container>
      <header className={style.header}>
        <div className={style.logo} onClick={() => history.push("/")}>
          <img src={logoUrl} alt="logo" />
        </div>
        <div className={style.nav}>
          <div className={style.navItem}><NavLink className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/room"}>办公选址</NavLink></div>
          <div className={style.navItem}><NavLink exact className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/cooperation"}>企业服务</NavLink></div>
          <div className={style.navItem}><NavLink exact className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/entrust"}>出租/求租</NavLink></div>
          <div className={style.navItem}><NavLink className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/news"}>新闻</NavLink></div>
          <div className={style.navItem}><NavLink exact className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/about"}>关于我们</NavLink></div>
        </div>
        <div className={style.tail}>
          <HeaderTail />
        </div>
        <div className={style.menuToggle} onClick={() => setShowToggleMenu(v => !v)}>
          <MenuOutlined />
        </div>
      </header>
      <div className={`pb-4 shadow ${showToggleMenu ? 'd-block' : 'd-none'} ${style.minNav}`}>
        <div className={"d-flex"}>
          <div>
            <div className={style.navItem}><NavLink className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/room"}>办公选址</NavLink></div>
            <div className={style.navItem}><NavLink exact className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/cooperation"}>企业服务</NavLink></div>
            <div className={style.navItem}><NavLink exact className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/entrust"}>出租/求租</NavLink></div>
            <div className={style.navItem}><NavLink className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/news"}>新闻</NavLink></div>
            <div className={style.navItem}><NavLink exact className={style.navItemLink} activeClassName={style.navItemLinkActive} to={"/about"}>关于我们</NavLink></div>
          </div>
          <div className={"ml-auto align-self-end mr-3 mb-2 position-relative"}>
            <HeaderTail isMin />
          </div>
        </div>

      </div>

    </Container>
  </div>
}

export default Header