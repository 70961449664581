import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import React, {useContext, useEffect, useState} from "react";
import {Api} from "../../service/Api";
import {ICooperationTypeVO, ICooperationVO} from "../../service/types/cooperation";
import style from './style.module.scss'
import {FrownOutlined} from "@ant-design/icons";
import Footer from "../../components/footer";
import {Helper} from "../../utils/Helper";
import {Helmet} from "react-helmet";
import MyPagination from "../../components/MyPagination/MyPagination";
import {GlobalContext} from "../../global/GlobalContext";

const pageSize = 20
export default function CoopService() {
    const [cooperationList, setCooperationList] = useState<ICooperationVO[]>([])
    const [cooperationTypeList, setCooperationTypeList] = useState<ICooperationTypeVO[]>([])
    const [filterType, setFilterType] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const [current, setCurrent] = useState<number>(1);


    useEffect(() => {
        setLoading(true)
        Api.cooperationList().then((data) => {
            setCooperationList(data.cooperation.list)
            setCurrent(1)
            setCooperationTypeList(data.type)
            setLoading(false)
        })
    }, [])
    function onFilterTypeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        let val = e.target.value
        // console.log(val);
        setFilterType(parseInt(val));
    }

    function onPageChange(page: number, pageSize: number) {
        setCurrent(page)
    }

    const list = filterType === 0 ? cooperationList : cooperationList.filter((item) => item.type === filterType)

    const total = list.length

    const list2 = list.slice((current - 1) * pageSize, current*pageSize)

    const {isM} = useContext(GlobalContext)
    return <div>
        <Helmet>
            <title>{Helper.htmlTitle('企业服务')}</title>
        </Helmet>
        <Header />
        <Container className={'page-main-content'}>
            <div className={"mt-4"}>
                <div className="d-flex">
                    <select className="form-control float-left" style={{width: 120}} value={filterType+''} onChange={onFilterTypeChange}>
                        <option value={0}>全部</option>
                        {
                            cooperationTypeList.map((item) => {
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                        }
                    </select>
                    {
                        filterType > 0 ? <button className={"btn btn-outline-danger ml-3"} onClick={() => {
                            setFilterType(0)
                            setCurrent(1)
                        }
                        }>清除</button> : null
                    }
                </div>
                <small className="form-text text-muted">
                    选择类型过滤
                </small>
            </div>
            <div className={"d-flex mt-2 p-2"} style={{borderBottom: '2px solid #d9d9d9'}}>
                <div className="flex-fill">
                    服务内容
                </div>
                {
                    isM ? null : <>
                        <div style={{width: 140}}>
                            类型
                        </div>
                        <div style={{width: 140}}>
                            联系方式
                        </div>
                    </>
                }

            </div>
            <div>
                {
                    list2.map((item) => {
                        return <Cooperation item={item} key={item.id} />
                    })
                }
                {
                    list.length === 0 && !loading ? (
                        <div className={"text-center mt-5"}>
                            <FrownOutlined style={{fontSize: 48, color: '#dce0e6'}}/>
                            <div className="mt-2 text-muted">暂无数据</div>
                        </div>
                    ) : null
                }
            </div>

            {
                total > 0 ? <MyPagination current={current} pageSize={pageSize} total={total} onChange={onPageChange} /> : null
            }
        </Container>
        <Footer />
    </div>
}


function Cooperation({item}: {item: ICooperationVO}) {
    const [imgUrl, setImgUrl] = useState<string>()
    const [showMore, setShowMore] = useState<boolean>(false)
    useEffect(() => {
        try {
            const urls = JSON.parse(item.img) as string[]
            if (urls.length > 0) {
                setImgUrl(urls[0])
            }
        } catch (e) {
            console.error('json 解析失败了')
        }

    }, [item])

    const {isM} = useContext(GlobalContext)

    return (
        <div key={item.id} className={`d-flex p-2 align-items-center ${style.CooperationItem}`}>
            <div className="align-self-start">
                <img src={imgUrl} className={`rounded ${style.leftImg}`} alt="图片"/>
            </div>
            <div className={`flex-fill pl-3 pr-3 ${style.Info}`}>
                <div className="font-weight-bold">{item.name}</div>
                <div className={`${!showMore && isM ? style.business : style.moreBusiness }`}>
                    {item.business}
                </div>

                {
                    isM ? <>
                        <div className={`${style.showMore}`}>
                            <span className={'text-primary'} onClick={()=> setShowMore(v => !v)}>
                                {showMore ?  '收起' : '全文'}
                            </span>
                        </div>
                        <div><span className="badge badge-primary">{item.type_name}</span></div>
                        <div>
                            <span className={"font-weight-bold"}>{item.contacts}</span>
                            <span className={"ml-2 text-danger font-weight-bold"}>{item.phone}</span>
                        </div>
                    </> : null
                }
            </div>
            {
                isM ? null : <>
                    <div style={{width: 140}} className="flex-shrink-0">
                        <span className="badge badge-primary">{item.type_name}</span>
                    </div>
                    <div className="text-left flex-shrink-0" style={{width: 140}}>
                        <div className={"font-weight-bold"}>{item.contacts}</div>
                        <div className={"text-danger font-weight-bold"}>{item.phone}</div>
                    </div>
                </>
            }

        </div>
    )
}
  