import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../global/GlobalContext";
import {Api} from "../../service/Api";
import {ICurrUserDetail} from "../../service/types/auth";
import style from './UserInfo.module.scss'
import {Helper} from "../../utils/Helper";
import UserType from "../../components/Header/UserType";
import NickName from "./components/NickName";
import {LoadingOutlined} from "@ant-design/icons";
import LoginName from "./components/LoginName";
import Email from "./components/Email";
import {Helmet} from "react-helmet";

export default function UserInfo() {
    const {currUserInfo, refreshUserInfo} = useContext(GlobalContext)
    const [detailInfo, setDetailInfo] = useState<ICurrUserDetail>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    useEffect(() => {
        if (currUserInfo) {
            setIsLoading(true)
            Api.userDetailInfo().then((data) => {
                setDetailInfo(data)
                setIsLoading(false)
            })
        }
    }, [currUserInfo])

    function onUserInfoChange() {
        refreshUserInfo?.();
        setIsLoading(true)
    }

    return <div>
        <Helmet>
            <title>{Helper.htmlTitle('个人信息')}</title>
        </Helmet>
        <div className={"mb-3 font-weight-bold"}>个人信息</div>
        <div className={style.infoWrap}>
            {isLoading ? (
                <div className={style.loadingArea}>
                    <div><LoadingOutlined/></div>
                    <div>加载中</div>
                </div>
            ) : null}

            <div className={`d-flex align-items-center mb-3`}>
                <div className={`text-muted ${style.infoLabel}`}>手机号</div>
                <div className={`flex-fill`}>{detailInfo?.Phone}</div>
            </div>
            <div className={`d-flex align-items-center mb-3`}>
                <div className={`text-muted ${style.infoLabel}`}>昵称</div>
                <div className={`flex-fill`}>
                    <NickName detailInfo={detailInfo} onChange={onUserInfoChange}/>
                </div>
            </div>
            <div className={`d-flex align-items-center mb-3`}>
                <div className={`text-muted ${style.infoLabel}`}>登录名</div>
                <div className={`flex-fill`}>
                    <LoginName detailInfo={detailInfo} onChange={onUserInfoChange}/>
                </div>
            </div>
            <div className={`d-flex align-items-center mb-3`}>
                <div className={`text-muted ${style.infoLabel}`}>邮箱</div>
                <div className={`flex-fill`}>
                    <Email detailInfo={detailInfo} onChange={onUserInfoChange}/>
                </div>
            </div>
            <div className={`d-flex align-items-center mb-3`}>
                <div className={`text-muted ${style.infoLabel}`}>等级</div>
                <div className={`flex-fill`}><UserType type={detailInfo?.Type || 1}/></div>
            </div>
            <div className={`d-flex align-items-center mb-3`}>
                <div className={`text-muted ${style.infoLabel}`}>微信</div>
                <div className={`flex-fill`}>{detailInfo?.WechatOpenid ? '已绑定' : '未绑定'}</div>
            </div>
            <div className={`d-flex align-items-center mb-3`}>
                <div className={`text-muted ${style.infoLabel}`}>注册时间</div>
                <div className={`flex-fill`}>{Helper.formatDate(detailInfo?.CreateTime || '')}</div>
            </div>
        </div>
    </div>;
}