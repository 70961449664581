import {ICurrUserDetail} from "../../../service/types/auth";
import React, {useEffect, useRef, useState} from "react";
import {Api} from "../../../service/Api";
import {CreateDisableDefaultErrorHandler} from "../../../service/r";

interface IProps {
    detailInfo?: ICurrUserDetail
    onChange?: () => void
}

const DefaultSmsBtbText = "获取验证码"

export default function Email({detailInfo, onChange}: IProps) {
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const instanceRef = useRef<{isSubmitting: boolean, countDownTimer: any}>({isSubmitting: false, countDownTimer: 0})

    const [value, setValue] = useState<string>('')
    const [emailInvalidFeedback, setEmailInvalidFeedback] = useState<string>('')
    const [emailIsInvalid, setEmailIsInvalid] = useState<boolean>(false)

    const [codeInvalidFeedback, setCodeInvalidFeedback] = useState<string>('')
    const [codeIsInvalid, setCodeIsInvalid] = useState<boolean>(false)
    const [code, setCode] = useState<string>('')

    const [smsBtnEnable, setSmsBtnEnable] = useState<boolean>(true);
    const [smsBtnText, setSmsBtnText] = useState<string>(DefaultSmsBtbText);

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            clearTimeout(instanceRef.current.countDownTimer)
        }
    }, [])

    function doInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value)
    }
    function onClickStartEdit(e: React.MouseEvent) {
        e.preventDefault()
        setShowEdit(true)
        setValue(detailInfo?.Email || '')
        setEmailInvalidFeedback('')
        setEmailIsInvalid(false)
        setCodeIsInvalid(false)
        setCodeInvalidFeedback('')
        setCode('')
    }

    function doFormSubmit(e: React.FormEvent) {
        e.preventDefault()
        if (instanceRef.current.isSubmitting) {
            return
        }

        const vRes = [
            validateEmail(),
            validateCode()
        ]
        if (vRes.filter(v => !v).length > 0) {
            return;
        }

        instanceRef.current.isSubmitting = true

        Api.updateEmail(value, code).then(() => {
            instanceRef.current.isSubmitting = false
            onChange?.()
            setShowEdit(false)
        }, CreateDisableDefaultErrorHandler((err) => {
            if (err && err.body) {
                setEmailIsInvalid(true)
                setEmailInvalidFeedback(err.body.msg)
            }
            instanceRef.current.isSubmitting = false
        }))
    }

    function doCancel() {
        setShowEdit(false)
    }

    function validateEmail() {
        if (value === '') {
            setEmailIsInvalid(true)
            setEmailInvalidFeedback("请输入邮箱，不能为空")
            return false
        }

        if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
            setEmailIsInvalid(true)
            setEmailInvalidFeedback("邮箱格式错误")
            return false
        }

        if (value.length > 30) {
            setEmailIsInvalid(true)
            setEmailInvalidFeedback("邮箱长度不超过30个字符")
            return false
        }

        setEmailIsInvalid(false)
        setEmailInvalidFeedback('')

        return true
    }

    function validateCode() {
        if (code === '') {
            setCodeIsInvalid(true)
            setCodeInvalidFeedback("请输入验证码")
            return false
        }

        if (code.length !== 6) {
            setCodeIsInvalid(true)
            setCodeInvalidFeedback("验证码长度为6位，请重新输入")
            return false
        }

        setCodeInvalidFeedback('')
        setCodeIsInvalid(false)
        return true
    }

    function sendCode() {

        if (!validateEmail()) {
            return;
        }

        let startTime = new Date().getTime();
        function loop() {
            let endTime = new Date().getTime();

            let limit = 60 - Math.ceil((endTime - startTime)/1000)
            console.log(limit);
            if (limit > 0) {
                setSmsBtnEnable(false)
                setSmsBtnText(`${limit}秒后重试`)
                instanceRef.current.countDownTimer = setTimeout(loop, 1000);
            } else {
                setSmsBtnEnable(true)
                setSmsBtnText(DefaultSmsBtbText)
            }
        }

        Api.sendEmailCode(value, "bind").then(() => {
            loop();
            setCodeIsInvalid(false)
            setCodeInvalidFeedback('')
        }, CreateDisableDefaultErrorHandler((e) => {
            if (e && e.body) {
                setCodeIsInvalid(true)
                setCodeInvalidFeedback(e.body.msg)
            }
        }))
    }
    return <div>
        {
            showEdit ? (
                    <form onSubmit={doFormSubmit}>
                        <div className="form-row">
                            <div className="form-group col">
                                <input id="email-input" value={value} onChange={doInputChange} type={"text"}
                                       className={`form-control ${emailIsInvalid ? 'is-invalid' : ''}`}
                                       placeholder={"输入邮箱"} />
                                <div className="invalid-feedback">
                                    {emailInvalidFeedback}
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <input
                                    id="email-code" value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    type={"text"}
                                    className={`form-control ${codeIsInvalid ? 'is-invalid' : ''}`}
                                    placeholder={"输入6位验证码"}
                                />
                                <div className="invalid-feedback">
                                    {codeInvalidFeedback}
                                </div>
                            </div>
                            <div className="form-group col">
                                <button className={"btn btn-outline-primary btn-block"} disabled={!smsBtnEnable} type="button" onClick={sendCode}>{smsBtnText}</button>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <button className={"btn btn-primary btn-block"} type="submit">保存</button>
                            </div>
                            <div className="form-group col">
                                <button className={"btn btn-secondary btn-block"} type="button" onClick={doCancel}>取消</button>
                            </div>
                        </div>
                    </form>
            ) : (
                <div>
                    {detailInfo?.Email || '未设置'}
                    <a href="#" className={"text-decoration-none ml-3"} onClick={onClickStartEdit}>设置</a>
                </div>
            )
        }
    </div>
}
