import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route
} from "react-router-dom";

import './App.scss';
import RoomList from "./pages/room/RoomList"
import RoomDetail from './pages/room/RoomDetail'
import CoopService from './pages/cooperation/CoopService'
import News from './pages/news/NewsList'
import About from './pages/about/About'
import Auth from './pages/auth/LoginRegister'
import {AppProvider} from "./global/GlobalContext";
import Entrust from "./pages/entrust/Entrust";
import NewsDetail from "./pages/news/NewsDetail";
import UserCenter from "./pages/userCenter/UserCenter";
import MyCollection from "./pages/myCollection/MyCollection";
import BaiduMapTest from "./pages/room/BaiduMapTest";

function App() {
    return (
        <AppProvider>
            <div className="App">
                <Router>
                    <Switch>
                        <Route path={'/'} exact>
                            <Redirect to={"/room"} />
                        </Route>
                        <Route path={'/room'} component={RoomList} exact/>
                        <Route path={'/room/:id'} component={RoomDetail} exact/>
                        <Route path={'/cooperation'} component={CoopService} exact/>
                        <Route path={'/news'} component={News} exact/>
                        <Route path={'/news/:id'} component={NewsDetail} exact/>
                        <Route path={'/about'} component={About}  exact/>
                        <Route path={'/entrust'} component={Entrust} exact/>
                        <Route path={'/auth'} component={Auth} />
                        <Route path={'/user_center'} component={UserCenter} />
                        <Route path={'/my_collection'} component={MyCollection} />
                        <Route path={'/baidu_map'} component={BaiduMapTest} />
                    </Switch>
                </Router>
            </div>
        </AppProvider>
    );
}

export default App;
