import style from './Header.module.scss';
import {useHistory} from 'react-router-dom';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {GlobalContext} from "../../global/GlobalContext";
import {DownOutlined} from "@ant-design/icons";
import {ICurrUser} from "../../service/types/auth";
import "./UserInfo.scss"
import {Api} from "../../service/Api";
import UserType from "./UserType";

const UserInfo: React.FC<{currUserInfo: ICurrUser, isMin?: boolean}>= ({currUserInfo, isMin}) => {
  const domRef = useRef<HTMLSpanElement>(null)
  const downRef = useRef<HTMLDivElement>(null)
  const { setCurrUserInfo } = useContext(GlobalContext)
  const [pos, setPos] = useState<{x: number, y: number}>({x: -999, y: -999})
  const [showDropMenu, setShowDropMenu] = useState<boolean>(false)
  const history = useHistory();

  function doCLickDropMenu(e:React.MouseEvent) {
    e.stopPropagation();
    setShowDropMenu(v => !v);
    setTimeout(() => {
      updatePos();
    }, 10)
  }

  useEffect(() => {
    function handler() {
      setShowDropMenu(false);
    }
    if (showDropMenu) {
      document.addEventListener("click", handler)
    }
    return () => {
      document.removeEventListener('click', handler)
    }
  }, [showDropMenu])

  function updatePos() {
    if (domRef.current) {
      const dd = domRef.current.getBoundingClientRect()
      // console.log(dd)
      setPos({x: dd.x + dd.width - 140, y: dd.y + dd.height})
    }
  }

  function doClickUserCenter(e: React.MouseEvent) {
    e.preventDefault();
    history.push("/user_center")
  }

  function doClickMyLike(e: React.MouseEvent) {
    e.preventDefault();
    history.push("/my_collection")
  }

  function doClickLogout(e: React.MouseEvent) {
    e.preventDefault()
    if (window.confirm("确认退出登录？")) {
      Api.doLogout().then(() => {
        setCurrUserInfo?.(undefined)
      })
    }
  }

  const ss: React.CSSProperties = {
    display: showDropMenu ? 'block': 'none',
    width: 120
  }

  if (isMin) {
    ss.right = 10
    ss.left = 'auto'
  } else {
    ss.left = pos.x
    ss.top = pos.y
  }

  return <>
    <span className={"d-inline-flex align-items-center user-info-area"} ref={domRef} onClick={doCLickDropMenu}>
      <UserType type={currUserInfo.Type}/>
      {
        currUserInfo.Name ? (
          <span className={"mr-2"}>
            {currUserInfo.Name}({currUserInfo.Phone})
          </span>
        ) : (
          <span className={"mr-2"}>
            {currUserInfo.Phone}
          </span>
        )
      }
      <DownOutlined style={{color: '#444'}} />
    </span>
    <div className="dropdown-menu" style={ss} ref={downRef}>
      <a className="dropdown-item" href="#" onClick={doClickUserCenter}>个人中心</a>
      <a className="dropdown-item" href="#" onClick={doClickMyLike}>我的收藏</a>
      <a className="dropdown-item text-danger" href="#" onClick={doClickLogout}>退出</a>
    </div>
  </>
}


interface HeaderTailProps {
  isMin?: boolean
}
export default function HeaderTail({isMin}: HeaderTailProps) {
  const history  = useHistory();
  const { currUserInfo } = useContext(GlobalContext)
  function doToLogin(e: React.MouseEvent) {
    e.preventDefault();
    history.push("/auth");
  }

  if (currUserInfo) {
    return <>
      <UserInfo currUserInfo={currUserInfo} isMin={isMin} />
    </>
  }

  return <a className={style.loginRegister} href={'#'} onClick={doToLogin}>登录/注册</a>
}
