import React from "react";

interface DescriptionProps {
    label: React.ReactNode
    value: React.ReactNode
}

export default function Description({label, value}: DescriptionProps) {
    return <dl className={"row mb-0"}>
        <dt className="col-sm-3">{label}</dt>
        <dd className="col-sm-9">{value}</dd>
    </dl>
}