export const Helper = {
    genKey(): string {
        return Math.random().toString(16).substr(2) + Math.random().toString(16).substr(2)
    },
  
    // 格式化，租金，面积
    showRealNum(price: number) {
      return Number(price/100)
    },
    // 显示月租金
    showMonthlyPrice(price: number) {
      return Number((price/(100 * 10000)).toFixed(2))
    },
    isValidDate(date: any) {
        return date instanceof Date && !isNaN(date.getTime())
    },
    formatDate(dateString: string) {
        if (!dateString) {
            return ''
        }
        let date = new Date(dateString)
        let yy = date.getFullYear()
        let mm = date.getMonth()
        let dd = date.getDate()
        let hh = date.getHours()
        let m = date.getMinutes()
        let s = date.getSeconds()
        if (!Helper.isValidDate(date)) {
            return ''
        }

        if (yy === 1) {
            return ''
        }

        function p2(num: number) {
            return ("0" + num).substr(-2);
        }

        return `${yy}-${p2(mm + 1)}-${p2(dd)} ${p2(hh)}:${p2(m)}:${p2(s)}`
    },

    htmlTitle(title: string): string {
        return `智远企服 - ${title}`
    }
  }
  