import React, {useEffect, useState} from "react";
import {Api} from "../../service/Api";
import style from "./style.module.scss";
import {ICheckItem} from "./index";

interface IAreaDistrictSelector {
    onChange: (area: ICheckItem | undefined, district: ICheckItem | undefined) => void
    visible: boolean
    onClose: () => void
}

export default function AreaDistrictSelector({onChange, onClose, visible}: IAreaDistrictSelector) {
    const [areaList, setAreaList] = useState<ICheckItem[]>([]);
    const [districtList, setDistrictList] = useState<ICheckItem[]>([]);

    const [area, setArea] = useState<ICheckItem>();
    const [district, setDistrict] = useState<ICheckItem>();

    useEffect(() => {
        Api.areaList().then((res) => {
            let items = [{ id: 0, value: 0, label: '不限' }];
            res.forEach((item) => {
                items.push({
                    id: item.ID,
                    label: item.Name,
                    value: item.ID,
                });
            });
            setAreaList(items);
        });
    }, []);

    function onAreaChange(item?: ICheckItem) {
        if (area === item) {
            return;
        }

        setArea(item);
        setDistrict(undefined);

        if (item === undefined) {
            return;
        }

        Api.districtList(item.value as number).then((res) => {
            let items = [{ id: 0, value: 0, label: '不限', dictIndex: '' }];
            res.forEach((item) => {
                items.push({
                    id: item.ID,
                    label: item.Name,
                    value: item.ID,
                    dictIndex: item.DictIndex,
                });
            });
            setDistrictList(items);
        });
    }

    function onDistrictChange(item?: ICheckItem) {
        if (district === item) {
            return;
        }
        setDistrict(item);
    }

    function doSubmit() {
        onChange?.(area, district)
    }

    function doReset() {
        setArea(undefined)
        setDistrict(undefined)
    }

    let currDictIndex = ''

    if (!visible) {
        return null
    }

    return (
        <>
            <div className={style.dropDownPanel}>
                <div className={"d-flex flex-column"} style={{height: '100%'}}>
                    <div className={"row no-gutters flex-fill border-bottom"} style={{height: 0}}>
                        <div className={"col-5 overflow-auto px-2 py-2"} style={{height: '100%'}}>
                            {
                                areaList.map((item) => {
                                    return <div
                                        key={item.id}
                                        className={`${style.selectItem} ${item === area ? style.active : ''}`}
                                        onClick={() => onAreaChange(item)}>
                                        {item.label}
                                    </div>
                                })
                            }
                        </div>
                        <div className={"col-7 p-2 overflow-auto"} style={{height: '100%', backgroundColor: '#efefef'}}>
                            {
                                area && area.id > 0 ? districtList.map((item) => {
                                    let isNew = false
                                    if (item.dictIndex && currDictIndex !== item.dictIndex) {
                                        currDictIndex = item.dictIndex
                                        isNew = true
                                    }
                                    if (isNew) {
                                        return <div key={item.id}>
                                            <div className={`text-danger ${style.dictIndex}`}>{item.dictIndex}</div>
                                            <div
                                                onClick={() => onDistrictChange(item)}
                                                className={ `${style.selectItem} ${district === item ? style.active : ''}`}
                                                key={item.id}>{item.label}</div>
                                        </div>
                                    }
                                    return <div
                                        onClick={() => onDistrictChange(item)}
                                        className={`${style.selectItem} ${district === item ? style.active : ''}`}
                                        key={item.id}>{item.label}</div>
                                }) : null
                            }
                        </div>
                    </div>

                    <div className={"container-fluid"}>
                        <div className={"row py-3"}>
                            <div className={"col-6"}>
                                <button className={"btn btn-block btn-secondary"} onClick={doReset}>重置</button>
                            </div>
                            <div className={"col-6"}>
                                <button className={"btn btn-block btn-primary"} onClick={doSubmit}>确定</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.dropMask} onClick={onClose}/>
        </>
    )
}