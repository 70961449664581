import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import React, { useEffect, useState } from "react";
import { Api } from "../../service/Api";
import { IPage } from "../../service/interface";
import { IRoomVO } from "../../service/room";
import { RoomResource } from "../room/RoomResource";
import MyPagination from "../../components/MyPagination/MyPagination";
import Footer from "../../components/footer";
import {Helper} from "../../utils/Helper";
import {Helmet} from "react-helmet";

export default function MyCollection () {
    const [search, setSearch] = useState<Partial<IPage>>({page_size: 20, page_no: 1});
    const [list, setList] = useState<IRoomVO[]>([]);
    const [current, setCurrent] = useState<number>();
    const [total, setTotal] = useState<number>();
    useEffect(() => {
        Api.myLikeRoom(search).then((data) => {
            setList(data.list)
            setCurrent(data.page.current)
            setTotal(data.page.total)
        })
    }, [search])

    function onPageChange(page: number, pageSize: number) {
        setSearch((q) => ({...q, page_no: page, page_size: pageSize}))
    }

    function doDelete(item: IRoomVO) {
        return (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            const con = window.confirm("确认删除")
            if (con) {
                Api.likeRoom(item.id, 'unlike').then(() => {
                    setSearch(v=>({...v}))
                })
            }
        }
    }

    return <div>
        <Helmet>
            <title>{Helper.htmlTitle('我的收藏')}</title>
        </Helmet>
        <Header />
        <Container>
            <div className={"mt-4 pl-3 page-main-content"}>
                <div className="h4"># 我的收藏</div>
                <RoomResource list={list} itemTailRender={(item) => {
                    return <div style={{paddingRight: 6}}>
                        <button className={'btn btn-danger'} onClick={doDelete(item)}>删除</button>
                    </div>
                }} />
                {/* 分页信息 */}
                <MyPagination current={current} total={total} onChange={onPageChange} />
            </div>
        </Container>
        <Footer />
    </div>
}