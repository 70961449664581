import style from "./style.module.scss";
import React, {useState} from "react";
import SortFilter, {ISortResult} from "../../pages/room/SortFilter";

interface IProps {
    visible: boolean
    onClose: () => void
    onChange?: (val: ISortResult | undefined) => void
}

export function SortSelector({visible, onChange, onClose}: IProps) {
    const [sortValue, setSortValue] = useState<ISortResult>()

    if (!visible) {
        return null
    }

    function doSubmit() {
        onChange?.(sortValue)
    }

    function doReset() {
        setSortValue(undefined)
    }

    function doSortFilterChange(val: ISortResult) {
        setSortValue(val)
    }

    return <>
        <div className={style.dropDownPanel}>
            <div className={"d-flex flex-column"} style={{height: '100%'}}>
                <div style={{height: 0}} className={'flex-fill border-bottom text-center'}>
                    <SortFilter onChange={doSortFilterChange} isMobile value={sortValue}/>
                </div>
                <div className={"container-fluid"}>
                    <div className={"row py-3"}>
                        <div className={"col-6"}>
                            <button className={"btn btn-block btn-secondary"} onClick={doReset}>重置</button>
                        </div>
                        <div className={"col-6"}>
                            <button className={"btn btn-block btn-primary"} onClick={doSubmit}>确定</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.dropMask} onClick={onClose}/>
    </>
}