import type {ICurrUser} from "../service/types/auth";
import React, {useCallback, useEffect, useState} from "react";
import {Api} from "../service/Api";
import {IBusinessConf} from "../service/types/conf";

interface IGlobalContextInfo {
    currUserInfo?: ICurrUser,
    setCurrUserInfo?: (val?: ICurrUser) => void
    refreshUserInfo?: () => void // 刷新用戶信息
    conf?: IBusinessConf,
    isM?: boolean
}

const defaultContextInfo: IGlobalContextInfo = {
    currUserInfo: undefined
}

export const GlobalContext = React.createContext<IGlobalContextInfo>(defaultContextInfo)

export const AppProvider: React.FC = ({children}) => {
    const [currUserInfo, setCurrUserInfo] = useState<ICurrUser | undefined>(undefined)
    const [conf, setConf] = useState<any>()
    const [isM, setIsM] = useState<boolean>(document.body.clientWidth <= 700)

    const freshUserInfoCallback = useCallback(() => {
        Api.userInfo().then((info) => {
            setCurrUserInfo(info)
        })
    }, [])

    // 初始化的时候尝试获取用户信息
    useEffect(() => {
        freshUserInfoCallback();
    }, [freshUserInfoCallback])

    useEffect(() => {
        // console.log(document.body.clientWidth)
        window.addEventListener('resize', () => {
            if (document.body.clientWidth <= 700) {
                setIsM(true)
            } else {
                setIsM(false)
            }
        })
    }, [])

    useEffect(() => {
        Api.businessConfig().then((data) => {
            // console.log('xxx',data);
            if (data.length > 0) {
                const config = JSON.parse(data[0].value)
                setConf(config)
            }
        })
    }, [])

    const val: IGlobalContextInfo = {
        currUserInfo: currUserInfo,
        setCurrUserInfo,
        conf,
        refreshUserInfo: freshUserInfoCallback,
        isM
    }

    return <GlobalContext.Provider value={val}>
        {children}
    </GlobalContext.Provider>
}