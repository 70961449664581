import style from './SortFilter.module.scss'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import {useEffect, useState} from 'react';

enum SortType {
  ASC = 'ASC', DESC = 'DESC'
}

export interface ISortResult {
  sort_field: string | undefined
  sort_type: SortType | undefined
}
interface ISortFilterProps {
  onChange?: (res: ISortResult) => void
  isMobile?: boolean
  value?: ISortResult
}
export default function SortFilter({onChange, isMobile, value}: ISortFilterProps) {
  const [field, setField] = useState<string>();
  // const [type, setType] = useState<SortType>();

  function onClickItem(f: string | undefined, s: SortType | undefined) {
    setField(f);
    // setType(s);
    onChange?.({sort_field: f, sort_type: s})
  }

  useEffect(() => {
    if (value === undefined) {
      setField(undefined)
    }
  }, [value])

  return <div className={`${isMobile ? style.MobileStyle : style.SortFilter}`}>
    <SortItem title={'默认排序'} name={undefined} isActive={field === undefined} onChange={onClickItem}/>
    <SortItem title={'面积'} name={'area'} isActive={field === 'area'} onChange={onClickItem} defaultSort={SortType.DESC} />
    <SortItem title={'日租金'} name={'day_rent'} isActive={field === 'day_rent'} onChange={onClickItem} defaultSort={SortType.ASC}/>
    <SortItem title={'最新'} name={'update_time'} isActive={field === 'update_time'} onChange={onClickItem} defaultSort={SortType.DESC}/>
  </div>
}

interface ISortItemProps {
  title: string
  name: string | undefined,
  type?: SortType
  defaultSort?: SortType
  onChange: (field: string | undefined, type: SortType | undefined) => void
  isActive: boolean
}

function SortItem({title, onChange, name, defaultSort, isActive}: ISortItemProps) {
  const [sortType, setSortType] = useState(defaultSort)
  function doClick() {

    if (sortType !== undefined) {
      if (!isActive) {
        onChange(name, sortType);
        return
      }

      if (sortType === SortType.ASC) {
        setSortType(SortType.DESC)
        onChange(name, SortType.DESC);
      } else {
        setSortType(SortType.ASC)
        onChange(name, SortType.ASC);
      }
    } else {
      onChange(name, sortType);
    }
  }
  const className = `${style.Item} ${isActive ? style.Active : ''}`
  return <div className={className} onClick={doClick}>
      {title}
      {sortType === undefined ? null : sortType === SortType.ASC ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
   </div>
}


