import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import type {IPage, IPageResponse, IResponse, IWithKey} from "./interface";
import {Helper} from "../utils/Helper";

const instance = axios.create({
    baseURL:"/api",
    timeout: 20000,
})

// 定义统一error对象
export class ApiError extends Error {
    // 当前请求的url
    public url?: string = ''

    // http 的状态信息
    public status: number = 0
    public statusText: string = ''

    // 后端返回的错误码
    public code?: number
    // 错误信息
    public message: string

    public isAxiosError: boolean = false
    public body?: any // 后端返回的错误信息

    public raw: any

    constructor(message: string) {
        super(message)
        this.message = message
    }
}

// class ApiError extends Error {
//     //status: number
//     //message: string
//     //url: string
//     constructor(
//         public url: string,
//         public status: number,
//         public statusText: string,
//         public message: string,
//         public isAxiosError: boolean
//     ) {
//         super(message);
//     }
// }

// class BizError extends Error {
//     constructor(public code: number, public message: string, public raw: object) {
//         super(message);
//     }

//     toString() {
//         return JSON.stringify({
//             code: this.code,
//             message: this.message,
//             raw: this.raw
//         })
//     }
// }

function doRequest<T>(config: AxiosRequestConfig): Promise<T> {
    const reqInstance = instance.request<IResponse<T>>(config).then(
        function onFulfilled(res) {
            // console.log(res)
            // console.log(res)
            const data = (res as AxiosResponse<IResponse<T>>).data

            if(data.code === 0) {
                return data.data
            } else {
                const e = new ApiError(data.msg)
                e.url = config.url
                e.status = res.status
                e.statusText = res.statusText
                e.code = data.code
                e.isAxiosError = false
                e.body = data
                e.raw = res
                return Promise.reject(e)
            }
        },
        function onRejected(err) {
            // console.dir(err)
            const {url} = err.config
            const {status, statusText, data} = err.response

            const e = new ApiError(err.message)
            e.url = url
            e.status = status
            e.statusText = statusText
            if (data && data.code) {
                e.code = data.code
            }
            e.isAxiosError = err.isAxiosError
            e.body = data
            e.raw = err
            return Promise.reject(e)
        }
    );

    // 局部变量用于标识是否禁用默认的错误处理
    let shouldDisableDefaultErrorHandle = false

    let oldThen = reqInstance.then

    // 自定义then方法
    function newThen(onFulfilled: (value: T) => any, onRejected?: Wrap<any> ) {
        // console.log('有 reject 吗？ ====', onRejected)

        let hasCatchHandler = false;

        if (onRejected?.shouldDisableDefaultErrorHandler) {
            shouldDisableDefaultErrorHandle = onRejected.shouldDisableDefaultErrorHandler
        }

        let newPromise = oldThen.call(reqInstance, onFulfilled, function myRejected(err) {
            // 如果传递了 onRejected 函数，就直接调用
            if (onRejected) {
                return onRejected(err)
            }

            // 用户时候在后面跟上了 catch 函数。就不吞并错误，让业务放也可以看得见错误。
            if (hasCatchHandler) {
                return Promise.reject(err)
            }

            console.log('这里直接吞并了错误');
        })

        // 对 newPromise 的 catch 添加切面
        let promiseCatch = newPromise.catch
        type RejectedFun = (err: any) => any
        function newPromiseCatch(onRejected: RejectedFun) {
            console.log('自定义的catch', onRejected)
            hasCatchHandler = true
            return promiseCatch.call(newPromise, function (err) {
                // console.log('执行了一个老的promise', err)
                return onRejected(err)
            })
        }

        newPromise.catch = newPromiseCatch

        return newPromise
    }

    reqInstance.then = newThen as any

    reqInstance.catch((err) => {
        if (!shouldDisableDefaultErrorHandle) {
            if (err instanceof ApiError) {
                if (err.code === 1004) {
                    return;
                }
                if (err.body && Object.prototype.toString.call(err.body) === '[object Object]') {
                    // message.error(`${err.body.code} ${err.body.msg}`)
                    alert(`${err.body.code} ${err.body.msg}`)
                } else {
                    // message.error(`${err.status} ${err.url} ${err.message}`)
                    alert(`${err.status} ${err.url} ${err.message}`)
                }
            } else {
                // message.error('其他错误')
                alert('其他错误')
            }

            console.dir(err)

            // 这里做一些特殊的逻辑
            // 比如：没有登录
            if (err.code === 3007) {
                window.location.href = "/login"
                return;
            }
        } else {
            console.warn('默认的错误处理函数已经被禁用了。请自行处理错误信息')
        }
    })

    return reqInstance
}

export function Get<T>(url: string, data: any = {}) {
    return doRequest<T>({
        method: "GET",
        url,
        params: data
    })
}

export function Post<T>(url: string, body: any = {}, params: any = {}) {
    return doRequest<T>({
        method: "post",
        url,
        data: body,
        params,
    })
}

export function doPageQuery<T extends IWithKey>(url: string, params: Partial<IPage>) {
    if (!params.page_no) {
        params.page_no = 1
    }

    if (!params.page_size) {
        params.page_size = 10
    }

    type ResObj<Type> = {
        list: Type[],
        page: any
    }

    const res = Post<IPageResponse<T>>(url, params)
    return onPromiseResolveBefore<IPageResponse<T>, ResObj<T>>(res, (data) => {
        data.list?.forEach((item) => {
            item.key = Helper.genKey()
        })
        const page = {
            total: data.total,
            current: params.page_no,
            pageSize: params.page_size,
            showQuickJumper: true,
            showTotal: (total: number) => {
                return `共${total}条数据`
            }
        }

        return {
            list: data.list || [],
            page
        }
    })
}

// 创建可以禁用默认错误处理的处理器
// 实现逻辑就是在函数上面添加一个错误的标识
type Wrap<TResult2=never> = {
    (reason: any): TResult2 | PromiseLike<TResult2>
    shouldDisableDefaultErrorHandler: boolean
}

type RejectedHandlerFun<T> = (reason: any) => T | PromiseLike<T>

export function CreateDisableDefaultErrorHandler<T = never>(func: RejectedHandlerFun<T>): Wrap<T> {
    let r: Wrap<T> = func as any
    r.shouldDisableDefaultErrorHandler = true
    return r
}

// 在 promise resolve 之前调用
export function onPromiseResolveBefore<T, P>(promise: Promise<T>, beforeHandler: (v: T) => P): Promise<P> {
    let oldThen = promise.then

    function newThen(onFulfilled: (data: any) => any, onRejected: () => any) {
        return oldThen.call(promise, function(data: any) {
            let data2 = beforeHandler(data)
            onFulfilled(data2)
        }, onRejected)
    }

    promise.then = newThen as typeof oldThen

    return promise as any as Promise<P>
}

// export function ExecSql(sql: string) {
//     return Post<IExecResult>("/util/exec_sql", {sql})
// }

// export function ExecQuery<T>(sql: string) {
//     return Post<T>("/util/exec_query", {sql})
// }
