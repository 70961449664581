import style from './Search.module.scss';
import React, {useContext, useEffect, useRef, useState} from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import {Api} from "../../service/Api";
import {GlobalContext} from "../../global/GlobalContext";

type IValueFormTo = { from: number | undefined, to: number | undefined }
type ICheckValue = string | number | IValueFormTo

export interface ICheckItem {
  id: number
  value: ICheckValue
  label: string
  dictIndex?: string
}

interface ICheckGroupProps {
  name?: string
  value?: ICheckItem
  items: ICheckItem[]
  className?: string
  onChange?: (val: ICheckItem) => void
  showRange?: boolean
  tail?: React.ReactNode
}

function CheckGroup({ name, value, items, className, onChange, showRange, tail }: ICheckGroupProps) {
  function doClick(item: ICheckItem) {
    return () => {
      (item as any).name = name;
      onChange?.(item);
    };
  }

  let currDictIndex = ''
  return <div className={`${className ? className : ''}`}>
    {
      items.map((item) => {
        let isNew = false
        if (item.dictIndex && currDictIndex !== item.dictIndex) {
          currDictIndex = item.dictIndex
          isNew = true
        }

        if (isNew) {
          return <span key={item.id}>
            <span className={`text-danger ${style.dictIndex}`}>{item.dictIndex}</span>
            <div
                className={`${style.CheckItem} ${value === item ? style.CheckItemActive : ''}`}
                key={item.id}
                onClick={doClick(item)}>
              {item.label}
            </div>
          </span>
        }

        return <div
          className={`${style.CheckItem} ${value === item ? style.CheckItemActive : ''}`}
          key={item.id}
          onClick={doClick(item)}>
          {item.label}
        </div>;
      })
    }
    { tail }
  </div>;
}


const numRegx = /^[1-9]\d*(\.\d*[1-9])?$|^0(\.\d*[1-9])?$/

function checkNum(v: string | undefined) {
  if (v === undefined || v.trim() === '') {
    return false;
  }
  return numRegx.test(v.trim())
}


interface ITailRangeProps {
  unit?: string
  onChange?: (val: ICheckItem) => void
}

function TailRange({unit, onChange}: ITailRangeProps) {
  const [v1, setV1] = useState<string>('')
  const [v2, setV2] = useState<string>('')
  const [showSubmit, setShowSubmit] = useState<boolean>(false);

  function checkShowSubmit(v1: string | undefined, v2: string | undefined) {
    setShowSubmit(checkNum(v1) && checkNum(v2))
  }

  function onV1Change(e: React.ChangeEvent<HTMLInputElement>) {
    setV1(e.target.value)
    checkShowSubmit(e.target.value, v2)
  }

  function onV2Change(e: React.ChangeEvent<HTMLInputElement>) {
    setV2(e.target.value)
    checkShowSubmit(v1, e.target.value)
  }

  function doSubmit() {
    setShowSubmit(false);
    onChange?.({ label: `${v1}-${v2}${unit}`, value: {from: parseFloat(v1) * 100 | 0, to: parseFloat(v2) * 100 | 0}, id: 100 })
    setV1('')
    setV2('')
  }

  return (
      <div className={`${style.CustomerRangeInput} ml-2`}>
        <input type="text" value={v1} onChange={onV1Change} className={"form-control form-control-sm"} />
        <span className={"ml-1 mr-1"}>至</span>
        <input type="text" value={v2} onChange={onV2Change} className={"form-control form-control-sm"} />
        <span className={"ml-1"}>{unit}</span>
        {
          showSubmit ? <button className={"btn btn-primary btn-sm ml-1"} onClick={doSubmit}>确定</button> : null
        }
    </div>
  )
}

interface ISearchItemProps {
  title: string
}

const SearchItem: React.FC<ISearchItemProps> = ({ title, children }) => {
  return (
    <div className={style.SearchItem}>
      <div className={style.SearchItemTitle}>
        <div className={style.SearchItemTitleText}>{title}</div>
      </div>
      <div className={style.SearchItemContent}>
        {children}
      </div>
    </div>
  );
};

interface IProps {
  onChange: (value: Record<string, number | string | undefined>) => void
}

export default function Search({ onChange }: IProps) {

  const {conf} = useContext(GlobalContext)

  // 已经选中了的条件
  const [area, setArea] = useState<ICheckItem>();
  const [district, setDistrict] = useState<ICheckItem>();
  const [roomArea, setRoomArea] = useState<ICheckItem>();
  const [rentPrice, setRentPrice] = useState<ICheckItem>();

  const [inputValue, setInputValue] = useState<string>('');

  const [areaList, setAreaList] = useState<ICheckItem[]>([]);
  const [districtList, setDistrictList] = useState<ICheckItem[]>([]);
  const [priceList, setPriceList] = useState<ICheckItem[]>([]);
  const [roomAreaList, setRoomAreaList] = useState<ICheckItem[]>([]);

  const instanceValue = useRef<Record<string, number | string | undefined>>({})

  useEffect(() => {
    Api.areaList().then((res) => {
      let items = [{ id: 0, value: 0, label: '不限' }];
      res.forEach((item) => {
        items.push({
          id: item.ID,
          label: item.Name,
          value: item.ID,
        });
      });
      setAreaList(items);
    });
  }, []);

  useEffect(() => {
    console.log('conf', conf)
    if (conf) {
      const price = conf['首页价格查询条件'];
      const priceList: ICheckItem[] = []
      price.forEach((item, index) => {
        let i: ICheckItem = {
          id: index,
          label:item.label,
          value: {from: item.from && item.from * 100 , to: item.to && item.to * 100}
        }
        priceList.push(i)
      })

      setPriceList(priceList)

      const area = conf['首页面积查询条件'];
      const roomAreaList: ICheckItem[] = []
      area.forEach((item, index) => {
        let i: ICheckItem = {
          id: index,
          label:item.label,
          value: {from: item.from && item.from * 100 , to: item.to && item.to * 100}
        }
        roomAreaList.push(i)
      })
      setRoomAreaList(roomAreaList)
    }
  }, [conf])

  function triggerChange() {
    onChange(instanceValue.current)
  }

  function onAreaChange(item?: ICheckItem) {
    if (area === item) {
      return;
    }

    setArea(item);
    setDistrict(undefined);

    if (item === undefined) {
      instanceValue.current.area_id = undefined
      instanceValue.current.district_id = undefined
      triggerChange()
      return;
    }

    instanceValue.current.area_id = item.value as number
    instanceValue.current.district_id = undefined
    triggerChange()

    Api.districtList(item.value as number).then((res) => {
      let items = [{ id: 0, value: 0, label: '不限', dictIndex: '' }];
      res.forEach((item) => {
        items.push({
          id: item.ID,
          label: item.Name,
          value: item.ID,
          dictIndex: item.DictIndex,
        });
      });
      setDistrictList(items);
    });
  }

  function onDistrictChange(item?: ICheckItem) {
    if (district === item) {
      return;
    }
    setDistrict(item);
    if (item === undefined) {
      instanceValue.current.district_id = 0;
    } else {
      instanceValue.current.district_id = item.value as number
    }

    triggerChange()
  }

  function _changeVal(item: ICheckItem | undefined, key1: string, key2: string) {
    if (item === undefined) {
      instanceValue.current[key1] = undefined
      instanceValue.current[key2] = undefined
    } else {
      const value = item.value as IValueFormTo;
      instanceValue.current[key1] = value.from
      instanceValue.current[key2] = value.to
    }
  }

  function onRoomAreaChange(item?: ICheckItem) {
    if (roomArea === item) {
      return;
    }
    setRoomArea(item);
    _changeVal(item, 'room_area_from', 'room_area_to');
    triggerChange()
  }

  function onRentPriceChange(item?: ICheckItem) {
    if (rentPrice === item) {
      return;
    }

    setRentPrice(item);
    _changeVal(item, 'price_from', 'price_to');
    triggerChange()
  }

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
  }

  function doTitleQuery(e: React.FormEvent) {
    e.preventDefault();
    instanceValue.current.title = inputValue
    triggerChange();
  }

  return <div className={style.Search}>
    <form onSubmit={doTitleQuery}>
      <div className={style.SearchBox}>
        <div className={style.SearchIcon}>
          <SearchOutlined />
        </div>
        <input
          type='text'
          value={inputValue}
          onChange={onInputChange}
          className={style.SearchInput}
          placeholder={'输入房源相关信息'}
        />
        <button className={style.SearchButton} type={'submit'}>
          搜房源
        </button>
      </div>
    </form>

    <SearchItem title={'位置'}>
      <CheckGroup items={areaList} name={'area_id'} onChange={onAreaChange} value={area}
                  className={style.AreaContainer} />
      {
        area && area.id > 0 ? (
          <CheckGroup
            items={districtList}
            name={'district_id'}
            onChange={onDistrictChange}
            value={district}
            className={style.DistrictContainer}
          />
        ) : null
      }

    </SearchItem>
    <SearchItem title={'面积'}>
      <CheckGroup
          items={roomAreaList}
          name={'area'}
          onChange={onRoomAreaChange} value={roomArea}
          tail={<TailRange unit={'平方'} onChange={onRoomAreaChange} />}
      />
    </SearchItem>

    <SearchItem title={'租金'}>
      <CheckGroup
          items={priceList}
          name={'rent'}
          onChange={onRentPriceChange}
          value={rentPrice}
          tail={<TailRange unit={'元'} onChange={onRentPriceChange} />}
      />
    </SearchItem>

    <SearchItem title={'已筛选条件'}>
      <div className={style.CheckedConditionWrap}>
        <CheckedCondition onChange={onAreaChange} item={area} />
        <CheckedCondition onChange={onDistrictChange} item={district} />
        <CheckedCondition onChange={onRoomAreaChange} item={roomArea} />
        <CheckedCondition onChange={onRentPriceChange} item={rentPrice} />
      </div>
    </SearchItem>
  </div>;
}

interface ICheckedCondition {
  item?: ICheckItem
  onChange?: (val?: ICheckItem) => void
}

function CheckedCondition({ item, onChange }: ICheckedCondition) {
  if (!item) {
    return null;
  }

  function doClose() {
    onChange?.(undefined);
  }

  return (
    <div className={style.CheckedCondition}>
      <div>{item.label}</div>
      <div className={style.Close} onClick={doClose}><CloseOutlined /></div>
    </div>
  );
}
