// import BaiduMap from "../../components/map";
// import "./BaiduMapTest.scss"
import RoomSearchCondition from "../../components/RoomSearchCondition";

export default function BaiduMapTest() {
    return <div>
        {/*<div className={"display-1"}>百度地图测试</div>*/}
        {/*<img src="//pic7.58cdn.com.cn/nowater/fangfe/n_v235b12beabdab41468ea78d148d6f2518.png" alt=""/>*/}
        {/*<BaiduMap />*/}

        {/*<div className="scroll-area">
            <div className={"scroll-content-item"}>这里是内容</div>
            <div className={"scroll-content-item"}>这里是内容</div>
            <div className={"scroll-content-item"}>这里是内容</div>
            <div className={"scroll-content-item"}>这里是内容</div>
            <div className={"scroll-content-item"}>这里是内容</div>
            <div className={"scroll-content-item"}>这里是内容</div>
        </div>*/}
        <RoomSearchCondition />
    </div>
}