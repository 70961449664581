import {CaretDownOutlined, SearchOutlined, SwapOutlined} from "@ant-design/icons";
import React, {useContext, useEffect, useRef, useState} from "react";
import {GlobalContext} from "../../global/GlobalContext";
import {ListSelector} from "./ListSelector";
import AreaDistrictSelector from "./AreaDistrictSelector";
import {SortSelector} from "./SortSelector";
import {ISortResult} from "../../pages/room/SortFilter";
import style from './style.module.scss'

interface IProps {
    onChange?: (value: Record<string, number | string | undefined>) => void
}

interface IRoomSearchContextValue {
    activeKey: string | undefined
    setActiveKey?: (key: string | undefined) => void
}

const RoomSearchContext = React.createContext<IRoomSearchContextValue>({activeKey: undefined});

type IValueFormTo = { from: number | undefined, to: number | undefined }
type ICheckValue = string | number | IValueFormTo


export interface ICheckItem {
    id: number
    value: ICheckValue
    label: string
    dictIndex?: string
}

export default function RoomSearchCondition({onChange}: IProps) {

    // room price
    const [priceList, setPriceList] = useState<ICheckItem[]>([]);
    // 面积
    const [roomAreaList, setRoomAreaList] = useState<ICheckItem[]>([]);

    const {conf} = useContext(GlobalContext)

    const instanceValue = useRef<Record<string, number | string | undefined>>({})

    const [inputValue, setInputValue] = useState<string>('');

    // 已经选择了的条件
    const [area, setArea] = useState<ICheckItem>();
    const [district, setDistrict] = useState<ICheckItem>();
    const [roomArea, setRoomArea] = useState<ICheckItem>();
    const [rentPrice, setRentPrice] = useState<ICheckItem>();

    const [activeKey, setActiveKey] = useState<string>()

    const [sortValue, setSortValue] = useState<ISortResult>();

    function _changeVal(item: ICheckItem | undefined, key1: string, key2: string) {
        if (item === undefined) {
            instanceValue.current[key1] = undefined
            instanceValue.current[key2] = undefined
        } else {
            const value = item.value as IValueFormTo;
            instanceValue.current[key1] = value.from
            instanceValue.current[key2] = value.to
        }
    }

    useEffect(() => {

    }, [area, district, roomArea, rentPrice, sortValue])

    function triggerChange() {
        console.log(instanceValue.current)
        onChange?.(instanceValue.current)
    }

    useEffect(() => {
        console.log('conf', conf)
        if (conf) {
            const price = conf['首页价格查询条件'];
            const priceList: ICheckItem[] = []
            price.forEach((item, index) => {
                let i: ICheckItem = {
                    id: index,
                    label:item.label,
                    value: {from: item.from && item.from * 100 , to: item.to && item.to * 100}
                }
                priceList.push(i)
            })

            setPriceList(priceList)

            const area = conf['首页面积查询条件'];
            const roomAreaList: ICheckItem[] = []
            area.forEach((item, index) => {
                let i: ICheckItem = {
                    id: index,
                    label:item.label,
                    value: {from: item.from && item.from * 100 , to: item.to && item.to * 100}
                }
                roomAreaList.push(i)
            })
            setRoomAreaList(roomAreaList)
        }
    }, [conf])

    function onAreaDistrictSelectorChange(val1: ICheckItem | undefined, val2: ICheckItem | undefined) {
        setArea(val1)
        setDistrict(val2)
        setActiveKey(undefined)
        if (val1 === undefined) {
            instanceValue.current.area_id = undefined
            instanceValue.current.district_id = undefined
        }

        if (val1 === undefined) {
            instanceValue.current.area_id = undefined
        } else {
            instanceValue.current.area_id = val1.value as number
        }

        if (val2 === undefined) {
            instanceValue.current.district_id = undefined
        } else {
            instanceValue.current.district_id = val2.value as number
        }

        triggerChange()
    }

    function onRoomAreaChange(val: ICheckItem | undefined) {
        setRoomArea(val)
        setActiveKey(undefined)
        _changeVal(val, 'room_area_from', 'room_area_to');
        triggerChange()
    }

    function onRentPriceChange(val: ICheckItem | undefined) {
        setRentPrice(val)
        setActiveKey(undefined)
        _changeVal(val, 'price_from', 'price_to');
        triggerChange()
    }

    function onSortChange(val: ISortResult | undefined) {
        setSortValue(val)
        setActiveKey(undefined)
        if (val) {
            instanceValue.current.sort_type = val.sort_type
            instanceValue.current.sort_field = val.sort_field
        } else {
            instanceValue.current.sort_type = undefined
            instanceValue.current.sort_field = undefined
        }

        triggerChange();
    }


    let areaTitle = '区域'
    if (area) {
        areaTitle = area.label
    }
    if (district) {
        areaTitle = district.label
    }

    let roomAreaTitle = '面积'
    if (roomArea) {
        roomAreaTitle = roomArea.label
    }

    let rentPriceTitle = '租金'
    if (rentPrice) {
        rentPriceTitle = rentPrice.label
    }

    let sortTitle = '排序'
    if (sortValue) {
        if (sortValue.sort_field === 'area') {
            sortTitle= '面积'
        }
        if (sortValue.sort_field === 'day_rent') {
            sortTitle = '租金'
        }

        if (sortValue.sort_field === 'update_time') {
            sortTitle = '更新时间'
        }
    }

    function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        setInputValue(e.target.value);
    }

    function doTitleQuery(e: React.FormEvent) {
        e.preventDefault();
        instanceValue.current.title = inputValue
        triggerChange();
    }

    return (
        <div>
            <form onSubmit={doTitleQuery}>
                <div className={style.SearchBox}>
                    <div className={style.SearchIcon}>
                        <SearchOutlined />
                    </div>
                    <input
                        type='text'
                        value={inputValue}
                        onChange={onInputChange}
                        className={style.SearchInput}
                        placeholder={'输入房源相关信息'}
                    />
                    <button className={style.SearchButton} type={'submit'}>
                        搜房源
                    </button>
                </div>
            </form>

            <SearchRoomTab onChange={setActiveKey} actKey={activeKey}>
                <SearchAction tabKey={'area'} title={areaTitle}/>
                <SearchAction tabKey={'roomArea'} title={roomAreaTitle}/>
                <SearchAction tabKey={'rentPrice'} title={rentPriceTitle}/>
                <SearchAction tabKey={'sort'} title={sortTitle} icon={<SwapOutlined />}/>
            </SearchRoomTab>

            <AreaDistrictSelector
                visible={activeKey === 'area'}
                onClose={() => {
                    // setActiveKey(undefined)
                }}
                onChange={onAreaDistrictSelectorChange}
            />

            <ListSelector
                list={roomAreaList}
                visible={activeKey === 'roomArea'}
                onClose={() => {
                    // setActiveKey(undefined)
                }}
                onChange={onRoomAreaChange}
            />

            <ListSelector
                list={priceList}
                visible={activeKey === 'rentPrice'}
                onClose={() => {
                    // setActiveKey(undefined)
                }}
                onChange={onRentPriceChange}
            />

            <SortSelector
                visible={activeKey === 'sort'}
                onClose={() => {
                   // setActiveKey(undefined)
                }}
                onChange={onSortChange}
            />
        </div>
    )
}

interface ISearchRoomTabProps {
    onChange: (key: string | undefined) => void
    actKey: string | undefined
}

const SearchRoomTab: React.FC<ISearchRoomTabProps> = ({actKey, children, onChange}) => {
    const [activeKey, setActiveKey] = useState<string | undefined>(actKey)
    useEffect(() => {
        setActiveKey(actKey)
    }, [actKey])

    function changeActiveKey(key: any) {
        setActiveKey(key)
        onChange?.(key)
    }

    const val: IRoomSearchContextValue = {
        activeKey,
        setActiveKey: changeActiveKey
    }

    return (
        <RoomSearchContext.Provider value={val}>
            <div className={`${style.selectBar} border-bottom`}>
                <div className={`row no-gutters py-3 px-2`} >
                    {children}
                </div>
            </div>
        </RoomSearchContext.Provider>
    )
}

interface ISearchActionProps {
    title: string
    icon?: React.ReactNode
    active?: boolean
    // activeKey: string
    tabKey: string
}
function SearchAction({title, icon, tabKey}: ISearchActionProps) {
    const {activeKey, setActiveKey} = useContext(RoomSearchContext)
    return <div
        className={`col-3 d-flex align-items-center px-2 justify-content-center ${activeKey === tabKey ? 'text-primary': ''}`}
        onClick={()=> {
            // console.log('====', tabKey, activeKey)
            if (tabKey === activeKey) {
                setActiveKey?.(undefined)
            } else {
                setActiveKey?.(tabKey)
            }
        }}
    >
        <div className={"text-truncate flex-fill"}>{title}</div>
        {
            icon ? icon : <CaretDownOutlined />
        }
    </div>
}

