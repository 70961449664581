import { doPageQuery, Get, Post } from './r';
import type {IExecResult, IPage, IPageResponse} from './interface';
import type { IRoomDetailVO, IRoomVO } from './room';
import {ICurrUser, ICurrUserDetail, ILoginDto, ILoginWithNameDto} from './types/auth';
import {ICooperationTypeVO, ICooperationVO} from "./types/cooperation";
import {INews} from "./types/news";

export interface IBannerVO {
  id: number
  img_url: string
  sort: number
}

export interface ICityVO {
  ID: number
  Name: string
}

export interface IAreaVO {
  ID: number
  Name: string
  CityId: number
  Sort: number
}

export interface IDistrictVO {
  AreaId: number //1
  DictIndex: string //"c"
  ID: number // 1
  Name: string // "朝阳门"
  Sort: number // 1
}

export const Api = {
  // 获取首页banner图片
  banner() {
    return Get<IBannerVO[]>('/banner', {})
  },
  // 城市
  cityList() {

  },
  // 区域
  areaList() {
    return Get<IAreaVO[]>("/conf/area")
  },

  // 商圈
  districtList(areaId: number) {
    return Get<IDistrictVO[]>("/conf/district", {area_id: areaId})
  },

  businessConfig() {
    return Get<{value: string}[]>("/conf/business")
  },

  roomList(params: Partial<IPage> = {}) {
    return doPageQuery<IRoomVO>("/room", params)
  },
  roomDetail(id: string) {
    return Get<IRoomDetailVO>('/room/'+ id, {})
  },

  isLikeRoom(id: string) {
    return Post<boolean>("/auth/is_like_room", {room_id: id})
  },

  likeRoom(id: string, type: 'like' | 'unlike') {
    return Post<IExecResult>("/auth/like_room", {room_id: id, type})
  },

  myLikeRoom(params: Partial<IPage>) {
    return doPageQuery<IRoomVO>("/auth/my_like", params)
  },

  sendSms(phone: string, scenes: string) {
    return Post("/auth/send_sms", { phone, scenes })
  },

  // 重置密码 发送短信验证码
  sendResetPasswordSmsCode() {
    return Post("/auth/send_sms_for_reset_password", { scenes: "reset_password" })
  },

  // 重置密码，验证用户发送的短信是否正常
  validateSmsCode(code: string) {
      return Post<string>("/auth/validate_sms_for_reset_password", { scenes: "reset_password", sms_code: code })
  },

  sendEmailCode(email: string, scenes: string) {
    return Post("/auth/send_email_code", { email, scenes })
  },

  resetPassword(p1: string, p2: string) {
    return Post("/auth/reset_password", {p1, p2})
  },

  doLogin(data: ILoginDto) {
    return Post<ICurrUser>("/auth/login_register", data);
  },

  doNameLogin(data: ILoginWithNameDto) {
    return Post<ICurrUser>("/auth/login", data)
  },

  doLogout() {
    return Post("/auth/logout")
  },

  userInfo() {
    return Post<ICurrUser>("/auth/info")
  },

  userDetailInfo() {
    return Post<ICurrUserDetail>("/auth/user_detail_info")
  },

  updateLoginName(loginName: string) {
    return Post<IExecResult>("/auth/update_login_name", {login_name: loginName})
  },

  updateNickName(name: string) {
    return Post<IExecResult>("/auth/update_name", {name})
  },

  updateEmail(email: string, code: string) {
    return Post<IExecResult>("/auth/update_email", {email, mail_code: code})
  },

  createEntrust(data: any) {
    return Post<IExecResult>("/entrust/create", data)
  },

  cooperationList() {
    return Get<{ type: ICooperationTypeVO[], cooperation: { total: number, list: ICooperationVO[] } }>("/cooperation/list")
  },

  newsList(pageNo = 1, pageSize = 20) {
    return Get<IPageResponse<INews>>("/news/list", { page_no: pageNo, page_size: pageSize })
  },

  newsDetail(id: string) {
    return Get<INews>("/news/detail", {id})
  }
}
