import {useEffect, useState} from "react";
import {Api} from "../../service/Api";
import IndexSwiper from "../../components/IndexSwiper/IndexSwiper";

export default function SwiperBanner() {
    const [imgList, setImgList] = useState<string[]>()
    useEffect(() => {
        Api.banner().then((data) => {
            setImgList(data.map((item) => {
                return item.img_url
            }))
        })
    }, []);

    return (
        <div className={'swiper-content'}>
            { imgList ? <IndexSwiper imgList={imgList} /> : null}
        </div>
    )
}