import React, {useContext, useEffect, useRef, useState} from "react";
import {GlobalContext} from "../../global/GlobalContext";
import {Api} from "../../service/Api";
import {CheckCircleOutlined} from "@ant-design/icons";
import {Helper} from "../../utils/Helper";
import {Helmet} from "react-helmet";

export default function SettingPassword() {
    const [showStep, setShowStep] = useState<number>(0)
    function next() {
        setShowStep(v => (v+1)%3)
    }

    return (
        <div>
            <Helmet>
                <title>{Helper.htmlTitle('修改密码')}</title>
            </Helmet>
            <div className={"mb-3 font-weight-bold"}>修改密码</div>
            <Step1 toNext={next} visible={showStep === 0}/>
            <Step2 toNext={next} visible={showStep === 1}/>
            <Step3 toNext={next} visible={showStep === 2}/>
        </div>
    )
}

interface IStepProps {
    toNext: () => void
    visible: boolean
}

const DefaultSmsBtbText = "获取验证码"

function Step1({toNext, visible}: IStepProps) {
    const {currUserInfo} = useContext(GlobalContext)

    const [codeInvalidFeedback, setCodeInvalidFeedback] = useState<string>('')
    const [codeIsInvalid, setCodeIsInvalid] = useState<boolean>(false)
    const [code, setCode] = useState<string>('')

    const [smsBtnEnable, setSmsBtnEnable] = useState<boolean>(true);
    const [smsBtnText, setSmsBtnText] = useState<string>(DefaultSmsBtbText);

    const instanceRef = useRef<{isSubmitting: boolean, countDownTimer: any}>({isSubmitting: false, countDownTimer: 0})

    useEffect(() => {
        if (visible) {
            setCode('')
        }
    }, [visible])

    function onSubmit(e: React.FormEvent) {
        e.preventDefault()
        if (instanceRef.current.isSubmitting) {
            return
        }

        if (!validateCode()) {
            return
        }

        instanceRef.current.isSubmitting = true

        // 这里会在redis 里面保存一个key
        Api.validateSmsCode(code).then(() => {
            toNext()
        }).finally(() => {
            instanceRef.current.isSubmitting = false
        })
    }

    function validateCode() {
        if (code === '') {
            setCodeIsInvalid(true)
            setCodeInvalidFeedback("请输入验证码")
            return false
        }

        if (code.length !== 6) {
            setCodeIsInvalid(true)
            setCodeInvalidFeedback("验证码长度为6位，请重新输入")
            return false
        }

        if (!/^\d{6}$/.test(code)) {
            setCodeIsInvalid(true)
            setCodeInvalidFeedback("格式错误，应该为6位数字")
            return false
        }

        setCodeInvalidFeedback('')
        setCodeIsInvalid(false)
        return true
    }

    function doSendCode() {
        let startTime = new Date().getTime();
        function loop() {
            let endTime = new Date().getTime();

            let limit = 60 - Math.ceil((endTime - startTime)/1000)
            console.log(limit);
            if (limit > 0) {
                setSmsBtnEnable(false)
                setSmsBtnText(`${limit}秒后重试`)
                instanceRef.current.countDownTimer = setTimeout(loop, 1000);
            } else {
                setSmsBtnEnable(true)
                setSmsBtnText(DefaultSmsBtbText)
            }
        }

        Api.sendResetPasswordSmsCode().then((data) => {
            console.log('data', data)
            loop();
        })
    }

    return <div className={`${visible ? 'd-block' : 'd-none'}`} style={{width: 400, maxWidth: '100%'}}>
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <span className={"form-text"}>系统将会给{currUserInfo?.Phone}发送短信验证码</span>
            </div>
            <div className="form-row">
                <div className="form-group col">
                    <input
                        id="email-code" value={code}
                        onChange={(e) => setCode(e.target.value)}
                        type={"text"}
                        className={`form-control ${codeIsInvalid ? 'is-invalid' : ''}`}
                        placeholder={"输入6位验证码"}
                    />
                    <div className="invalid-feedback">
                        {codeInvalidFeedback}
                    </div>
                </div>
                <div className="form-group col">
                    <button className="btn btn-outline-primary" disabled={!smsBtnEnable} type="button" onClick={doSendCode}>{smsBtnText}</button>
                </div>
            </div>

            <div className="form-group">
                <button className={"btn btn-primary"} type={'submit'}>下一步</button>
            </div>
        </form>
    </div>
}

function Step2({toNext, visible}: IStepProps) {

    const [p1, setP1] = useState<string>('')
    const [p2, setP2] = useState<string>('')

    const [vp1, setVp1] = useState<boolean>(false)
    const [vp1msg, setVp1msg] = useState('')

    const [vp2, setVp2] = useState<boolean>(false)
    const [vp2msg, setVp2msg] = useState('')

    const instanceRef = useRef<{isSubmitting: boolean, countDownTimer: any}>({isSubmitting: false, countDownTimer: 0})

    useEffect(() => {
        if (visible) {
            setP1('')
            setP2('')
        }
    }, [visible])

    function onP1Change(e: React.ChangeEvent<HTMLInputElement>) {
        setP1(e.target.value)
        validateP1(e.target.value)
    }

    function validateP1(p1: string) {
        if (p1.length  < 8 || p1.length > 50) {
            setVp1(true)
            setVp1msg("密码长度为8至50位")
            return false
        }

        if (!(/[a-z]/.test(p1) && /[A-Z]/.test(p1) && /[0-9]/.test(p1))) {
            setVp1(true)
            setVp1msg("密码必须同时包含大写字母，小写字母和数字")
            return false
        }

        setVp1(false)
        setVp1msg('')
        return true
    }

    function validateP2(p2: string) {
        if (p1 !== p2) {
            setVp2(true)
            setVp2msg("两次输入密码不相同")
            return false
        }

        setVp2(false)
        setVp2msg('')
        return true
    }

    function onP2Change(e: React.ChangeEvent<HTMLInputElement>) {
        setP2(e.target.value)
        validateP2(e.target.value)
    }

    function doSubmit(e: React.FormEvent) {
        e.preventDefault()

        if (instanceRef.current.isSubmitting) {
            return;
        }

        const ves = [
            validateP1(p1),
            validateP2(p2)
        ]

        if (ves.filter(v=> !v).length > 0) {
            return;
        }

        instanceRef.current.isSubmitting = true

        Api.resetPassword(p1, p2).then(() => {
            toNext();
        }).finally(() => {
            instanceRef.current.isSubmitting = false
        })
    }
    return <div className={`${visible ? 'd-block' : 'd-none'}`} style={{width: 300}}>
        <form onSubmit={doSubmit}>
            <div className="form-group">
                <label htmlFor="p1">密码</label>
                <input type="password"
                       className={`form-control ${vp1 ? 'is-invalid' : ''}`}
                       id="p1"
                       value={p1}
                       onChange={onP1Change}
                       placeholder="请输入密码"/>
                <div className="invalid-feedback">
                    {vp1msg}
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="p2">确认密码</label>
                <input type="password"
                       className={`form-control ${vp2 ? 'is-invalid' : ''}`}
                       id="p2"
                       value={p2}
                       onChange={onP2Change}
                       placeholder="请再次输入密码"/>
                <div className="invalid-feedback">
                    {vp2msg}
                </div>
            </div>
            <div className="form-group">
                <button className={"btn btn-primary"}>确定</button>
            </div>
        </form>
    </div>
}

function Step3({toNext, visible}: IStepProps) {
    return (
        <div className={`text-center ${visible ? 'd-block' : 'd-none'}`} style={{width: 300}}>
            <div style={{fontSize: 48}} className={"text-success mb-4"}>
                <CheckCircleOutlined />
            </div>
            <div>密码设置成功，下就可以使用新密码登录了</div>
            <div className="mt-4">
                <button className="btn btn-primary" style={{width: 120}} onClick={toNext}>返回</button>
            </div>
        </div>
    )
}