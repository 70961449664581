import React, {useContext, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import style from './RoomDetail.module.scss'
import {IRoomDetailVO} from "../../service/room";
import {Api} from "../../service/Api";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import ImageViewer from "./components/ImageViewer";
import {Helper} from "../../utils/Helper";
import {GlobalContext} from "../../global/GlobalContext";
import {HeartTwoTone, PhoneOutlined} from "@ant-design/icons";
import {useHistory} from 'react-router-dom'
import Footer from "../../components/footer";
import BaiduMap from "../../components/map";
import Description from "./components/Description";
import {Helmet} from 'react-helmet'

export default function RoomDetail() {
  const [loading, setLoading] = useState<boolean>(true);
  const [info, setInfo] = useState<IRoomDetailVO>();
  const {id} = useParams<{ id: string }>();
  useEffect(() => {
    // console.log(params);
    setLoading(true);
    Api.roomDetail(id).then((data) => {
      // console.log(data);
      try {
        data.images = JSON.parse(data.images as string) as string[]
        data.building_images = JSON.parse(data.building_images as string) as string[]
        setInfo(data);
      } catch (err) {
        console.error('解析JSON对象失败了')
        setInfo(data)
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [id]);

  return <div>
    <Header />
    <Container>
      {
        loading ? <span>加载中...</span> : null
      }

      {
        !loading && !info ? <span>房源信息错误</span> : null
      }

      {
        info ? <DetailInfo info={info} /> : null
      }
    </Container>
    <Footer />
  </div>;
}

interface IDetailInfoProps {
  info: IRoomDetailVO
}

const SectionItemStyle: React.CSSProperties = {
  marginBottom: 30
}

function DetailInfo({ info }: IDetailInfoProps) {
  const {conf} = useContext(GlobalContext)

  const [payTypeMap, setPayTypeMap] = useState<Record<number, string>>({})
  useEffect(() => {
    if (conf) {
      const types: Record<number, string> = {}
      conf['付款方式'].forEach((item) => {
        types[item.value] = item.label
      })
      setPayTypeMap(types);
    }
  }, [conf])

  return <div className={"mt-4"}>
    <Helmet>
      <title>{info.title}</title>
    </Helmet>
    <h2 className={style.title}>{info.title}</h2>
    <div className={"row"}>
      <div className={"col-sm-12 col-lg-6"} style={SectionItemStyle}>
        <ImageViewer images={info.images as string[]} />
      </div>
      <div className="col-sm-12 col-lg-6" style={SectionItemStyle}>
        <RightDetail info={info} payTypeMap={payTypeMap} />
      </div>
    </div>

    <div className={"row"}>
      <div className="col-sm-12 col-lg-6" style={SectionItemStyle}>
        <div className={style.BlockInfoFull}>
          <div className="font-weight-bold mb-2">基本信息</div>
          <div>{info.detail.split("\n").map((item, index) => {
            return <div key={index}>{item}</div>
          })}</div>
        </div>
      </div>

      <div className="col-sm-12 col-lg-6" style={SectionItemStyle}>
        <div className={style.BlockInfoFull}>
          <div className="font-weight-bold mb-2">项目信息</div>
          <Description label={'地址'} value={info.building_address}/>
          <Description label={'开发商'} value={info.building_developer}/>
          <Description label={'物业'} value={info.building_property_company}/>
          <Description label={'产权年限'} value={<span>{info.building_property_year}年</span>}/>
          <Description label={'公交'} value={info.building_bus}/>
          <Description label={'地铁'} value={info.building_subway}/>
          <Description label={'周边配套'} value={info.building_around}/>
          <Description label={'空调开放时间'} value={<span>
            {
              info.building_air_type === 1 ? (
                  <span className={"text-success"}>24小时制</span>
              ) : (
                <>
                  <span className={"text-info mr-3"}>非24小时制</span>
                  <span>{info.building_air_desc}</span>
                </>
            )}
          </span>}/>
          <Description label={'停车位'} value={info.building_park}/>
          <Description label={'层高'} value={<span>{info.building_floor_height}米</span>}/>
        </div>
      </div>
    </div>

    <div className={style.section3}>
      <BaiduMap lng={info.building_longitude} lat={info.building_latitude}/>
    </div>
  </div>;
}

function RightDetail({info, payTypeMap}: {info: IRoomDetailVO, payTypeMap: Record<number, string>}) {
  const {isM} = useContext(GlobalContext)
  return (
      <div className="d-flex flex-column" style={{height: '100%'}}>
        <div className="flex-fill">
          <h3>{info.building_name}</h3>
          <div className="mb-3">
            {info.can_register === 1 ? <span className={"badge badge-primary"}>可注册</span> : <span className={"badge badge-secondary"}>不可注册</span>}
            {info.divisible === 1 ? <span className={"badge badge-primary ml-2"}>可分割</span> : <span className={"badge badge-secondary ml-2"}>不可分割</span>}
          </div>

          <div className="row no-gutters mt-2" style={{lineHeight: 1.2}}>
            <div className="col">
              <small className="text-muted">面积</small>
              <div>
                <span style={{fontSize: 28, fontWeight: 700}}>{Helper.showRealNum(info.area)}m²</span>
              </div>
            </div>
            {
              info.room_no ? (
                  <div className="col">
                    <small className="text-muted">房间号</small>
                    <div>
                      <span style={{fontSize: 28, fontWeight: 700}}>{info.room_no}</span>
                    </div>
                  </div>
              ) : null
            }
          </div>

          <div className='row no-gutters mb-4 mt-4' style={{lineHeight: 1.2}}>
            <div className="col">
              <small className={"text-muted"}>日租金</small>
              <div className="text-danger">
                <span style={{fontSize: 28}}>{Helper.showRealNum(info.day_rent)}</span>
                <span className="ml-2">元/m²/天</span>
              </div>
            </div>
            <div className="col">
              <small className={"text-muted"}>月租金</small>
              <div className="text-danger">
                <span style={{fontSize: 28}}>{Helper.showMonthlyPrice(info.month_rent)}</span>
                <span className="ml-2">万元/月</span>
              </div>
            </div>
          </div>

          <div className={`mb-3 ${style.BlockInfo}`}>
            {
              info.divisible === 1 ? (
                  <Description label={'分割信息'} value={info.divide_desc}/>
              ) : null
            }
            <Description label={'付款方式'} value={payTypeMap[info.pay_type]}/>
            <Description label={'租金包含'} value={info.rent_contain}/>
            <Description label={'房间状态'} value={info.room_status}/>
            <Description label={'产权方'} value={info.property_party}/>
          </div>

        </div>

        {
          isM ? (
              <div>
                <div className={`flex-fill d-flex align-items-center justify-content-center ${style.phoneArea}`}>
                  <PhoneOutlined/>
                  <a href={'tel:13051215688'} className={"ml-3 text-white"}>13051215688</a>
                </div>
                <div>
                  <UserLike id={info.id}/>
                </div>
              </div>
          ) : (
              <div>
                <div className={`d-flex align-items-stretch`}>
                  <div className={`flex-fill d-flex align-items-center justify-content-center mr-3 ${style.phoneArea}`}>
                    <PhoneOutlined/>
                    <span className={"ml-3"}>13051215688</span>
                  </div>
                  <div>
                    <UserLike id={info.id}/>
                  </div>
                </div>
              </div>
          )
        }

      </div>
  )
}


interface IUserLikeProps {
  id: string
}
function UserLike({id}: IUserLikeProps) {
  const history = useHistory();
  const { currUserInfo } = useContext(GlobalContext)

  const [hasLike, setHasLike] = useState<boolean>(false)
  const [text, setText] = useState<string>(hasLike ? '已收藏' : '收藏');

  useEffect(() => {
    if (currUserInfo) {
      Api.isLikeRoom(id).then((isLike) => {
        // console.log('islike', isLike);
        setHasLike(isLike);
        if (isLike) {
          setText('已收藏')
        }
      })
    }
  }, [id, currUserInfo]);

  function doMouseEnter() {
    if (hasLike) {
      setText('取消收藏')
    } else {
      setText("添加收藏")
    }
  }
  function doMouseLeave() {
    if (hasLike) {
      setText("已收藏")
    } else {
      setText("收藏")
    }
  }

  function doToggle() {
    if (!currUserInfo) {
      history.push("/auth")
    }

    Api.likeRoom(id, hasLike ? 'unlike' : 'like').then(() => {
      setHasLike(v => !v)
      if (hasLike) {
        setText("添加收藏")
      } else {
        setText("已收藏")
      }
    })
  }

  return (
      <div className={style.userLike} onMouseEnter={doMouseEnter} onMouseLeave={doMouseLeave} onClick={doToggle}>
        <HeartTwoTone twoToneColor={ hasLike ? "#dc3545" : "#999"} style={{fontSize: '32px'}}/>
        <small>{text}</small>
      </div>
  )
}