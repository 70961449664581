import {ICurrUserDetail} from "../../../service/types/auth";
import React, {useRef, useState} from "react";
import {Api} from "../../../service/Api";

interface IProps {
    detailInfo?: ICurrUserDetail
    onChange?: () => void
}
export default function NickName({detailInfo, onChange}: IProps) {
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const [value, setValue] = useState<string>('')
    const instanceRef = useRef<{isSubmitting: boolean}>({isSubmitting: false})
    function doInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value)
    }
    function onClickStartEdit(e: React.MouseEvent) {
        e.preventDefault()
        setShowEdit(true)
        setValue(detailInfo?.Name || '')
    }

    function doFormSubmit(e: React.FormEvent) {
        e.preventDefault()
        if (instanceRef.current.isSubmitting) {
            return
        }
        instanceRef.current.isSubmitting = true
        Api.updateNickName(value).then(() => {
            instanceRef.current.isSubmitting = false
            onChange?.()
            setShowEdit(false)
        }).catch(() => {
            instanceRef.current.isSubmitting = false
        })
    }

    function doCancel() {
        setShowEdit(false)
    }
    return <div>
        {
            showEdit ? (
                    <form className="form-inline" onSubmit={doFormSubmit}>
                        <div className="form-group">
                            <input id="inputPassword2" value={value} onChange={doInputChange} type={"text"} className="form-control" placeholder={"输入昵称"} />
                        </div>
                        <button className={"btn btn-primary ml-3"} type="submit">保存</button>
                        <button className={"btn btn-secondary ml-3"} type="button" onClick={doCancel}>取消</button>
                    </form>
            ) : (
                <div>
                    {detailInfo?.Name || '未设置'}
                    <a href="#" className={"text-decoration-none ml-3"} onClick={onClickStartEdit}>设置</a>
                </div>
            )
        }
    </div>
}
