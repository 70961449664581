import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import style from "./style.module.scss";
import Footer from "../../components/footer";
import React from "react";
import {Helper} from "../../utils/Helper";
import {Helmet} from "react-helmet";

interface ITitleProps {}

const Title: React.FC<ITitleProps> = ({ children }) => {
  return (
    <div className="mb-3 text-center" style={{ fontSize: 22 }}>
      {children}
    </div>
  );
};

export default function About() {
  return (
    <div>
      <Helmet>
        <title>{Helper.htmlTitle('关于我们')}</title>
      </Helmet>
      <Header />
      <div className={`${style.content}`}>
        <div className={`py-5 ${style.section1}`}>
          <div className={`${style.section1Bg}`} />
          <Container>
            <div style={{ position: "relative" }}>
              <Title>公司简介</Title>
              <div className={style.contentText}>
                智远企服（北京）信息技术有限公司，成立于2021年5月5日，总部位于北京朝阳，是一家专业从事企业服务的公司，其产品服务类型多样化、全面化、细节化，帮助企业解决日常经营需求，定制专项解决方案并协助推进落地，从而帮助企业达到开源节流，减少运营成本的目的。
              </div>
              <div className={style.contentText}>
                公司从创业伊始，始终秉承着专业、责任、高效、真诚的最高行为准则，以客户为中心，以客户方利益为至上，帮助企业优化日常经营成本，最终实现共赢。
              </div>
            </div>
          </Container>
        </div>

        <div className={`py-5 ${style.section2}`}>
          <Container>
            <div className="text-center" style={{ fontSize: 22 }}>
              使命
            </div>
            <div className={`${style.subTitle} text-muted mb-3`}>
              做企业后方有保障、值得信赖的行政助理
            </div>
            <div className={style.contentText}>
              我们致力于打造一个公平、透明、诚信的互动平台，可以实现资源互换共享，并利用这些资源去满足企业运营中所要的各种需求，如办公租赁，金融服务，财税规划，工商服务，产品宣传推广等，所有问题解决将更加简单，实现真正意义上的一站式服务。
            </div>
          </Container>
        </div>

        <div className={`py-5`}>
          <Container>
            <Title>价值观</Title>
            <div className={`row ${style.wItemWrap}`}>
              <ServiceItem name="专业" desc={<span>多年的从业经验<br />良好的口碑形象</span>} />
              <ServiceItem name="责任" desc={<span>不推卸，不敷衍<br />我们全力以赴</span>} />
              <ServiceItem name="高效" desc={<span>优秀的团队<br />丰富的资源<br/>无可挑剔的执行力！</span>} />
              <ServiceItem name="真诚" desc={<span>诚实守信为本<br />诚实做人，真诚待人</span>} />
            </div>
          </Container>
        </div>

        <div className="pt-5">
          <Container>
            <Title>服务介绍</Title>
            <div className={`row ${style.sItmeWrap}`}>
              <ServiceItem name="商办选址" desc="商业办公选址" />
              <ServiceItem name="金融服务" desc="金融贷款，垫资融资" />
              <ServiceItem name="工商服务" desc="注册迁址，企业变更" />
              <ServiceItem name="会议场地" desc="会议会展，场地挑选" />
              <ServiceItem name="会展策划" desc="场地搭建，设备安排" />

              <ServiceItem name="产品策划" desc="产品包装，营销策划" />
              <ServiceItem name="商务出行" desc="接机会客，车型提供" />
              <ServiceItem name="团队拓展" desc="团建娱乐，游玩出行" />
              <ServiceItem name="资源对接" desc="匹配商业资源精准对接" />
              <ServiceItem name="财税策划" desc="税务咨询规划定制方案" />

              <ServiceItem
                name="法律咨询"
                desc={<span>专业律师团队<br/>提供相应解决方案</span>}
              />
              <ServiceItem
                name="办公设备"
                desc={<span>类型筛选推荐<br/>与供货商对接<br/>享受折扣价</span>}
              />
              <ServiceItem
                name="装修设计"
                desc={<span>数据测量<br/>方案设计<br/>材料估价</span>}
              />
              <ServiceItem
                name="广告宣传"
                desc={<span>多方位，全角度<br/>强大的互联网信息平台</span>}
              />
              <ServiceItem
                name="网站建设"
                desc={<span>专业团队，方案设计<br/>升级维护，运营指导</span>}
              />
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
}

interface IServiceItem {
  name: string;
  desc: string | React.ReactNode;
}
function ServiceItem({ name, desc }: IServiceItem) {
  return (
    <div className={`col-lg-3 col-sm-6`}>
      <div className={`${style.sItme}`}>
        <div className={style.sItemTitle}>{name}</div>
        <div>{desc}</div>
      </div>
    </div>
  );
}
