import type {IRoomVO} from '../../service/room';
import style from './RoomResource.module.scss';
import {Helper} from '../../utils/Helper';
import {useHistory} from 'react-router-dom';
import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../global/GlobalContext";

interface IProps {
    list: IRoomVO[]
    itemTailRender?: (item: IRoomVO) => React.ReactNode
}

export function RoomResource({list, itemTailRender}: IProps) {
    const {conf} = useContext(GlobalContext)
    const [payTypeMap, setPayTypeMap] = useState<Record<number, string>>({})
    useEffect(() => {
        if (conf) {
            const types: Record<number, string> = {}
            conf['付款方式'].forEach((item) => {
                types[item.value] = item.label
            })
            setPayTypeMap(types);
        }
    }, [conf])

    return (
        <div>
            {
                list?.map((item) => {
                    return <RoomResourceItem itemTailRender={itemTailRender} payTypeMap={payTypeMap} room={item} key={item.id}/>
                })
            }
        </div>
    )
}

interface IRoomResourceItemProps {
    room: IRoomVO
    payTypeMap: Record<number, string>
    itemTailRender?: (item: IRoomVO) => React.ReactNode
}

function RoomResourceItem({room, payTypeMap, itemTailRender}: IRoomResourceItemProps) {
    const history = useHistory();
    const {isM} = useContext(GlobalContext)

    function doClickItem() {
        // history.push('/room/' + room.id)
        if (isM) {
            history.push('/room/' + room.id)
        } else {
            window.open(window.location.origin + '/room/' + room.id)
        }
    }

    let img
    if (room.cover_image) {
        img = room.cover_image + '?x-oss-process=image/resize,w_320,m_lfit'
    } else {
        // 默认图片
        img = 'https://zhiyuqifu.oss-cn-beijing.aliyuncs.com/test/90aaaac3b02c8e484bd58e34d479e6b8.png?x-oss-process=image/resize,w_320,m_lfit'
    }

    return (
        <div className={style.RoomResourceItem} onClick={doClickItem}>
            <div className={style.CoverImgWrap}>
                <img src={img} className={style.CoverImage} alt='封面图'/>
            </div>
            <div className={style.InfoCenter}>
                <div className={style.Title}>{room.title}</div>
                <div>{room.area_name} / {room.district_name} / {room.building_name}</div>
                <div className={style.rentInfo}>
                    <span className="text-muted mr-1">付款方式:</span>
                    <span className={"text-info mr-3"}>{payTypeMap[room.pay_type]}</span> 
                    
                    <span className="text-muted mr-1">租金包含: </span>
                    <span>{room.rent_contain}</span>
                </div>
                <div className={style.roomStatus}>
                    <span className="text-muted mr-1">房间状态:</span>
                    <span>{room.room_status}</span>
                </div>
                <div className={style.registerInfo}>
                    {room.can_register ? <span className="badge badge-primary mr-2">可注册</span> : null}
                    {room.divisible === 1 ? <span className="badge badge-primary">可分割</span> :
                        <span className="badge badge-primary">不可分割</span>}
                </div>

                <div className={style.miniRoomPrice}>
                    <div className={`text-danger ${style.dayPrice}`}>
                        <span>{Helper.showRealNum(room.day_rent)}</span>
                        元/m²/天
                    </div>

                    <div className={`text-danger ${style.monthPrice}`}>
                        <span>{Helper.showMonthlyPrice(room.month_rent)}</span>万/月
                    </div>
                </div>

                {
                    isM && itemTailRender?.(room)
                }
            </div>
            <div className={style.RoomArea}>
                <div>
                    <span className={style.AreaNum}>{Helper.showRealNum(room.area)}</span>
                    <span className={style.AreaUnit}>m²</span>
                </div>
                <div className={style.AreaUnitTips}>建筑面积</div>
            </div>
            <div className={style.RoomPrice}>
                <div className={"text-danger"}>
                    <span className={style.Price}>{Helper.showRealNum(room.day_rent)}</span>
                    <span className={style.PriceUnit}>元/m²/天</span>
                </div>
                <div className={style.PriceMonthly}>{Helper.showMonthlyPrice(room.month_rent)}万/月</div>
            </div>

            {
                !isM && itemTailRender?.(room)
            }
        </div>
    )
}
