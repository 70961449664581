import {useEffect, useRef, useState} from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import style from './ImageViewer.module.scss';
import {Helper} from "../../../utils/Helper";
import {ImageItem} from "./type/type";
import ViewImageV2 from "./ViewImageV2";

interface ImageViewerProps {
  images: string[]
}

export default function ImageViewer({images}: ImageViewerProps) {
  const [list, setList] = useState<ImageItem[]>([])
  const [curr, setCurr] = useState<ImageItem>();
  const [showBigImg, setShowBigImg] = useState(false)

  const indicatorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const imageItemList = images.map((item, index) => {
      return {
        id: Helper.genKey(),
        src: item,
        index,
      }
    });
    setList(imageItemList)
    if (imageItemList.length > 0) {
      setCurr(imageItemList[0])
    }
  }, [images])

  // const len = list.length;

  function doLeft() {
    if (indicatorRef.current) {
      indicatorRef.current.scrollLeft -= 100;
    }
  }

  function doRight() {
    if (indicatorRef.current) {
      indicatorRef.current.scrollLeft += 100;
    }
  }

  return (
      <>
        <div className={`d-flex flex-column ${style.ImageViewer}`}>
          <div className={`flex-fill mb-3 ${style.HighlightImage}`}>
            {
              curr ? <img src={curr.src} className={style.bigImg} alt={"大图"} onClick={() => setShowBigImg(true)}/> : null
            }
          </div>
          <div className={style.Indicator}>
            <div className={style.actionBar} onClick={doLeft}><LeftOutlined /></div>
            <div className={style.IndicatorContent} ref={indicatorRef}>
              {
                list.map((item) => {
                  return <div className={`${style.IndicatorItem} ${item === curr ? style.IndicatorItemActive : ''}`} key={item.id} onClick={() => setCurr(item)}>
                    <img src={item.src} className={style.IndicatorImg} alt='indicator' />
                  </div>
                })
              }
            </div>
            <div className={style.actionBar} onClick={doRight}><RightOutlined /></div>
          </div>
        </div>
        <ViewImageV2 visible={showBigImg} list={list} index={curr?.index} onClose={() => setShowBigImg(false)}/>
        {/*<ViewImage visible={showBigImg} src={curr?.src} onClose={() => setShowBigImg(false)}/>*/}
      </>
  )
}

