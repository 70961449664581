import Pagination, { PaginationProps } from 'rc-pagination';
import './index.css';
import React from 'react';

const customerStyle: React.CSSProperties = {
  textAlign: 'center',
}

const wrapStyle: React.CSSProperties = {
  padding: `20px 0`,
}

export default function MyPagination(props: PaginationProps = {}) {
  return (
    <div style={wrapStyle}>
      <Pagination
        style={customerStyle}
        showQuickJumper={{goButton: true}}
        showTotal={(total) => {
          return <span>总共{total}条数据</span>;
        }}
        itemRender={(current, type, element) => {
          if (type === 'prev') {
            return <button className={'prev-btn'}>上一页</button>;
          }
          if (type === 'next') {
            return <button className={'next-btn'}>下一页</button>;
          }
          return element;
        }}
        {...props}
      />
    </div>
  );
}
