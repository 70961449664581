import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import Search from './Search';
import { RoomResource } from './RoomResource';
import React, {useContext, useEffect, useState} from 'react';
import { IRoomVO } from '../../service/room';
import MyPagination from '../../components/MyPagination/MyPagination';
import { Api } from '../../service/Api';
import type { IPage } from '../../service/interface';
import SortFilter, { ISortResult } from './SortFilter';
import SwiperBanner from "../index/SwiperBanner";
import Footer from "../../components/footer";
import {Helmet} from "react-helmet";
import {Helper} from "../../utils/Helper";
import RoomSearchCondition from "../../components/RoomSearchCondition";
import {GlobalContext} from "../../global/GlobalContext";

export default function RoomList() {
  const [search, setSearch] = useState<Partial<IPage>>({page_size: 20, page_no: 1});
  const [list, setList] = useState<IRoomVO[]>([]);
  const [current, setCurrent] = useState<number>();
  const [total, setTotal] = useState<number>();
  const {isM} = useContext(GlobalContext)

  useEffect(() => {
    Api.roomList(search).then((data) => {
      // console.log(data)
      setList(data.list)
      setCurrent(data.page.current)
      setTotal(data.page.total)
    })
  }, [search])

  function onSearchChange(val: any) {
    setSearch(q => ({...val, page_no: 1, page_size: q.page_size}));
  }

  function onPageChange(page: number, pageSize: number) {
    setSearch((q) => ({...q, page_no: page, page_size: pageSize}))
  }

  function doSortFilterChange(val: ISortResult) {
    setSearch(q => ({...q, ...val, page_no: 1}))
  }

  return (
    <div>
      <Helmet>
        <title>{Helper.htmlTitle('办公选址')}</title>
      </Helmet>
      <Header />
      <SwiperBanner />
      <Container className={"page-main-content"}>
          {
              isM ? (
                  <div style={{marginLeft: -15, marginRight: -15}}>
                      <RoomSearchCondition onChange={doSortFilterChange as any}/>
                  </div>
              ) : <>
                  <Search onChange={onSearchChange} />
                  <SortFilter onChange={doSortFilterChange}/>
              </>
          }

        <RoomResource list={list} />
        {/* 分页信息 */}
        <MyPagination current={current} pageSize={20} total={total} onChange={onPageChange} />
      </Container>
      <Footer />
    </div>
  )
}
