import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import style from './style.module.scss'
import {useLocation, useRouteMatch, Switch, Route, useHistory, Redirect} from 'react-router-dom'
import UserInfo from "./UserInfo";
import SettingPassword from "./SettingPassword";

export default function UserCenter() {
    const location = useLocation()
    let { path } = useRouteMatch()
    const history = useHistory()
    function doChangeRoute(urlStr: string) {
        return () => {
            history.push(urlStr)
        }
    }

    return (
        <div>
            <Header/>
            <Container>
                <div className="mt-4">
                    <div className="h4"># 个人中心</div>
                    <div className={`mt-3 ${style.main}`}>
                        <div className={style.left}>
                            <div>
                                <button onClick={doChangeRoute(`${path}/user_info`)} className={` btn ${location.pathname === path + '/user_info' ? 'btn-primary': 'btn-outline-primary'}`}>个人信息</button>
                                <button onClick={doChangeRoute(`${path}/setting_password`)} className={`btn ml-3 ${location.pathname === path + '/setting_password' ? 'btn-primary':'btn-outline-primary'}`}>修改密码</button>
                            </div>
                        </div>
                        <div className={`flex-fill ${style.right}`}>
                            <Switch>
                                <Route path={`${path}`} exact>
                                    <Redirect to={`${path}/user_info`}/>
                                </Route>
                                <Route path={`${path}/user_info`}>
                                    <UserInfo/>
                                </Route>
                                <Route path={`${path}/setting_password`}>
                                    <SettingPassword />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>

            </Container>
        </div>
    )
}