import {ICurrUserDetail} from "../../../service/types/auth";
import React, {useRef, useState} from "react";
import {Api} from "../../../service/Api";
import {CreateDisableDefaultErrorHandler} from "../../../service/r";

interface IProps {
    detailInfo?: ICurrUserDetail
    onChange?: () => void
}

export default function LoginName({detailInfo, onChange}: IProps) {
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const [value, setValue] = useState<string>('')
    const instanceRef = useRef<{ isSubmitting: boolean }>({isSubmitting: false})
    const [errMsg, setErrMsg] = useState<string>('')
    function doInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value)
    }

    function onClickStartEdit(e: React.MouseEvent) {
        e.preventDefault()
        setShowEdit(true)
        setValue(detailInfo?.LoginName || '')
        setErrMsg('')
    }

    function doFormSubmit(e: React.FormEvent) {
        e.preventDefault()
        if (instanceRef.current.isSubmitting) {
            return
        }

        if (value.length === 0) {
            setErrMsg("不能为空")
            return
        }

        if (value.length < 4 || value.length > 30) {
            setErrMsg("长度须大于等于4并且小于等于30")
            return
        }

        setErrMsg('')

        instanceRef.current.isSubmitting = true
        Api.updateLoginName(value).then(() => {
            instanceRef.current.isSubmitting = false
            onChange?.()
            setShowEdit(false)
        }, CreateDisableDefaultErrorHandler((err) => {
            // console.log(err)
            if (err.body) {
                setErrMsg(err.body.msg)
            }
            instanceRef.current.isSubmitting = false
        }))
    }

    function doCancel() {
        setShowEdit(false)
    }

    return <div>
        {
            showEdit ? (
                <>
                    <form className="form-inline" onSubmit={doFormSubmit}>
                        <div className="form-group">
                            <input id="inputPassword2" value={value} onChange={doInputChange} type={"text"}
                                   className="form-control" placeholder={"输入登录名"}/>
                        </div>
                        <button className={"btn btn-primary ml-3"} type="submit">保存</button>
                        <button className={"btn btn-secondary ml-3"} type="button" onClick={doCancel}>取消</button>
                    </form>
                    <small className="form-text text-muted">登录名只能设置一次，并且不能重复，可以用于登录 <br/>长度大于等于4并且小于等于30</small>
                    <small className="form-text text-danger">{errMsg}</small>
                </>
            ) : (
                <div>
                    {detailInfo?.LoginName || '未设置'}
                    {
                        !detailInfo?.LoginName ? <a href="#" className={"text-decoration-none ml-3"} onClick={onClickStartEdit}>设置</a> : null
                    }
                </div>
            )
        }
    </div>
}
