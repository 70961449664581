import style from "./LoginRegister.module.scss";
import React, {useContext, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Api} from "../../service/Api";
import {GlobalContext} from "../../global/GlobalContext";
import logUrl from '../../components/Header/logo.png'
import {Helper} from "../../utils/Helper";
import {Helmet} from "react-helmet";
const PhoneRegx = /^1\d{10}$/
const DefaultSmsBtbText = "获取验证码"
const SmsCodeRegx= /^\d{6}$/

enum LoginTypeEnum {
  SmsCode,
  Password
}

export default function LoginRegister() {

  const {setCurrUserInfo} = useContext(GlobalContext)

  const history = useHistory()
  const [phone, setPhone] = useState<string>('')
  const [smsCode, setSmsCode] = useState<string>('')
  const [loginName, setLoginName] = useState<string>('')
  const [password, setPassword] = useState<string>('')


  // 验证状态信息
  const [phoneIsInvalid, setPhoneIsInvalid] = useState<boolean>();
  const [phoneInvalidFeedback, setPhoneInvalidFeedback] = useState<string>('')

  const [loginNameIsInvalid, setLoginNameIsInvalid] = useState<boolean>();
  const [loginNameInvalidFeedback, setLoginNameInvalidFeedback] = useState<string>('')


  const [passwordIsInvalid, setPasswordIsInvalid] = useState<boolean>();
  const [passwordInvalidFeedback, setPasswordInvalidFeedback] = useState<string>('')

  const [smsCodeIsInvalid, setSmsCodeIsInvalid] = useState<boolean>();
  const [smsCodeInvalidFeedback, setSmsCodeInvalidFeedback] = useState<string>('');


  const [smsBtnEnable, setSmsBtnEnable] = useState<boolean>(true);
  const [smsBtnText, setSmsBtnText] = useState<string>(DefaultSmsBtbText);

  const [loginType, setLoginType] = useState<LoginTypeEnum>(LoginTypeEnum.SmsCode)

  const instance = useRef<{countDownTimer: any, isSubmitting: boolean}>({countDownTimer: 0, isSubmitting: false})

  function onFormSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (instance.current.isSubmitting) {
      return false
    }

    if (loginType === LoginTypeEnum.SmsCode) {
      doSubmitSmsCodeForm();
    }

    if (loginType === LoginTypeEnum.Password) {
      doSubmitPasswordForm();
    }
  }

  function doSubmitSmsCodeForm() {
    if (!validatePhone()) {
      return
    }

    if (!validateSmsCode()) {
      return
    }

    instance.current.isSubmitting = true

    Api.doLogin({
      phone: phone,
      sms_code: smsCode
    }).then((data) => {
      console.log('登录成功', data)
      // alert('登录成功')
      setCurrUserInfo?.(data);
      clearTimeout(instance.current.countDownTimer)
      if (history.length > 0) {
        history.goBack();
      } else {
        history.replace('/')
      }
    }).finally(() => {
      instance.current.isSubmitting = false
    })
  }

  function doSubmitPasswordForm() {
    const vRes = [
        validateLoginName(),
        validatePassword(),
    ]

    if (vRes.filter(v => !v).length > 0) {
      return false
    }

    instance.current.isSubmitting = true

    Api.doNameLogin({ password, login_name: loginName }).then((data) => {
      setCurrUserInfo?.(data)
      if (history.length > 0) {
        history.goBack();
      } else {
        history.replace('/')
      }
    }).finally(() => {
      instance.current.isSubmitting = false
    })
  }

  function onPasswordChange(e : React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value)
  }

  function onPhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPhone(e.target.value)
  }

  function onLoginNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLoginName(e.target.value)
  }

  function onSmsCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSmsCode(e.target.value)
  }

  function validatePhone() {
    if (!phone) {
      setPhoneIsInvalid(true)
      setPhoneInvalidFeedback("请输入手机号")
      return false
    }

    if (!PhoneRegx.test(phone)) {
      setPhoneIsInvalid(true)
      setPhoneInvalidFeedback("手机号格式错误")
      return false
    }

    setPhoneIsInvalid(false)
    setPhoneInvalidFeedback('')
    return true
  }

  function validateSmsCode() {
    if (!smsCode) {
      setSmsCodeIsInvalid(true)
      setSmsCodeInvalidFeedback("请输入6位短信验证码")
      return false
    }

    if (!SmsCodeRegx.test(smsCode)) {
      setSmsCodeIsInvalid(true)
      setSmsCodeInvalidFeedback("短信验证码格式错误")
      return false
    }

    setSmsCodeIsInvalid(false)
    setSmsCodeInvalidFeedback("")
    return true;
  }

  function validateLoginName() {
    if (loginName === '') {
      setLoginNameIsInvalid(true)
      setLoginNameInvalidFeedback("请输入登录名")
      return false
    }
    setLoginNameIsInvalid(false)
    setLoginNameInvalidFeedback('')
    return true
  }

  function validatePassword() {
    if (password === '') {
      setPasswordIsInvalid(true)
      setPasswordInvalidFeedback('登录密码不能为空')
      return false
    }

    setPasswordInvalidFeedback('')
    setPasswordIsInvalid(false)
    return true
  }

  function doGetSmsCode() {
    if (!validatePhone()) {
      return;
    }

    let startTime = new Date().getTime();
    function loop() {
      let endTime = new Date().getTime();

      let limit = 60 - Math.ceil((endTime - startTime)/1000)
      console.log(limit);
      if (limit > 0) {
        setSmsBtnEnable(false)
        setSmsBtnText(`${limit}秒后重试`)
        instance.current.countDownTimer = setTimeout(loop, 1000);
      } else {
        setSmsBtnEnable(true)
        setSmsBtnText(DefaultSmsBtbText)
      }
    }

    Api.sendSms(phone, "login").then((data) => {
      console.log('data', data)
      loop();
    })


  }

  function doChangeToSmsCode() {
    setLoginType(LoginTypeEnum.SmsCode)
  }

  function doChangeToPassword() {
    setLoginType(LoginTypeEnum.Password)
  }

  return <div>
    <Helmet>
      <title>{Helper.htmlTitle('登录/注册')}</title>
    </Helmet>
    <div className={style.loginHeader}>
      <img src={logUrl} alt="Logo" className={style.logoImg}/>
      <span className={style.headerText}>- 登录认证</span>
    </div>
    <div className={style.loginWrap}>
      <div className={style.loginRegister}>
        <div className={style.login_left}>
          <div>诚 信</div>
          <div>透 明</div>
          <div>共 享</div>
          <div>共 赢</div>
        </div>
        <div className={style.login_right}>
          <div className={style.formTitleWrap}>
            <div className={style.formTitle}>登录/注册</div>
            {/*<div className={style.formSubTitle}>注册新账号</div>*/}
          </div>

          <form onSubmit={onFormSubmit}>
            {
              loginType === LoginTypeEnum.Password ? (
                <div className={"form-group"}>
                  <label htmlFor="el-login-name">账号</label>
                  <input
                      type='text'
                      id={'el-login-name'}
                      className={`form-control ${loginNameIsInvalid ? 'is-invalid' : ''}`}
                      value={loginName}
                      onChange={onLoginNameChange}
                      placeholder={'输入手机号、登录名或者邮箱'}
                  />
                  <div className="invalid-feedback">
                    {loginNameInvalidFeedback}
                  </div>
                </div>
              ) : null
            }

            {
              loginType === LoginTypeEnum.SmsCode ? (
                <div className={"form-group"}>
                  <label htmlFor="el-phone">手机号</label>
                  <input
                      type='text'
                      id={'el-phone'}
                      className={`form-control ${phoneIsInvalid ? 'is-invalid' : ''}`}
                      value={phone}
                      onChange={onPhoneChange}
                      placeholder={'请输入手机号'}
                  />
                  {/*<small className={"form-text text-muted"}>如果手机号未注册，将会自动注册并登录</small>*/}
                  <div className="invalid-feedback">
                    {phoneInvalidFeedback}
                  </div>
                </div>
              ) : null
            }


            {/* 验证码登录 */}
            {
              loginType === LoginTypeEnum.SmsCode ? (
                  <div className={"form-group"}>
                    <label htmlFor="el-phone">验证码</label>
                    <div className={"row"}>
                      <div className={"col-7"}>
                        <input
                            type='text'
                            id={'el-sms-code'}
                            className={`form-control ${smsCodeIsInvalid ? 'is-invalid' : ''}`}
                            value={smsCode}
                            onChange={onSmsCodeChange}
                            placeholder={"请输入6位数字"}
                        />
                        <div className="invalid-feedback">
                          {smsCodeInvalidFeedback}
                        </div>
                      </div>
                      <div className={"col-5"}>
                        <button
                            className={`btn btn-outline-primary btn-block`}
                            disabled={!smsBtnEnable}
                            type={'button'}
                            onClick={doGetSmsCode}>{smsBtnText}</button>
                      </div>
                    </div>
                  </div>
              ) : null
            }
            {/* 密码登录方式 */}
            {
              loginType === LoginTypeEnum.Password ? (
                  <div className={"form-group"}>
                    <label htmlFor="el-password">密码</label>
                    <input
                        type='password'
                        id={'el-password'}
                        className={`form-control ${passwordIsInvalid ? 'is-invalid' : ''}`}
                        value={password}
                        onChange={onPasswordChange}
                        placeholder={'输入登录密码'}
                    />
                    <div className="invalid-feedback">
                      {passwordInvalidFeedback}
                    </div>
                  </div>
              ) : null
            }

            <div className={style.loginSwitch}>
              {
                loginType === LoginTypeEnum.SmsCode ? (
                    <div className={`${style.loginTypeItem}`} onClick={doChangeToPassword}>密码登录</div>
                ) : null
              }

              {
                loginType === LoginTypeEnum.Password ? (
                    <>
                      {/*<div className={`${style.loginTypeItem}`} onClick={doChangeToSmsCode}>忘记密码</div>*/}
                      <div className={`${style.loginTypeItem} ml-auto`} onClick={doChangeToSmsCode}>验证码登录</div>
                    </>
                ) : null
              }
            </div>

            <div className={'mt-4'}>
              <button type={"submit"} className={'btn btn-block btn-primary'}>登录</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}
