import { useEffect, useRef } from 'react';
import "./IndexSwiper.scss";

interface IProps {
  imgList: string[]
}
export default function IndexSwiper({imgList}: IProps) {
  const swiperContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tpl = `<div class="swiper-container">
  <div class="swiper-wrapper">
      ${
      imgList.map((item) => {
        return `<div class='swiper-slide' style='background-image: url(${item})'></div>`
      }).join('')
    }
  </div>
  <div class="swiper-pagination"></div>
  <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>
</div>`

    if (!swiperContent.current) {
      return
    }

    if (swiperContent.current) {
      // console.log(tpl);
      swiperContent.current.innerHTML = tpl;
    }

    const mySwiper = new (window as any).Swiper(swiperContent.current.querySelector('.swiper-container'), {
      autoplay: true,
      loop: true, // 循环模式选项

      // 如果需要分页器
      pagination: {
        el: swiperContent.current.querySelector('.swiper-pagination'),
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: swiperContent.current.querySelector('.swiper-button-next'),
        prevEl: swiperContent.current.querySelector('.swiper-button-prev'),
      },
    })

    return () => {
      mySwiper.destroy()
      if (swiperContent.current) {
        swiperContent.current.innerHTML = ''
      }
    }
  }, [imgList])

  return (
    <div ref={swiperContent} />
  )
}
