import vip1Url from './vip1.png'
import vip2Url from './vip2.png'
import vip3Url from './vip3.png'
import React from "react";

const vipImgStyle: React.CSSProperties = {
    width: "66px",
    height: "19px",
    backgroundSize: "80px",
    backgroundPosition: "-6px 0px"
}

const VIPMap: Record<number, React.ReactNode> = {
    1: <div style={{...vipImgStyle, backgroundImage: `url(${vip1Url})`}}/>,
    2: <div style={{...vipImgStyle, backgroundImage: `url(${vip2Url})`}}/>,
    3: <div style={{...vipImgStyle, backgroundImage: `url(${vip3Url})`}}/>,
}

export interface IProps {
    type: number
}
export default function UserType({type}: IProps) {
    return <>{VIPMap[type]}</>
}