import React from 'react';
import style from "./Container.module.scss";

interface IProps {
  className?: string
}

const Container: React.FC<IProps> = ({className, children}) => {
  const classNames = `${className || ''} ${style.Container}`
  return <div className={classNames}>{children}</div>
}

export default Container
